import React from 'react';
import { connect } from 'react-redux';
import { edit } from './../Actions/news';
import { getList as getStoreObjects } from './../Actions/storeObject';
import { getList as getNewsCategory } from './../Actions/newsCategory';
import { getList as getNewsSource } from './../Actions/newsSource';
import { getOne } from './../Actions/news';
import EditNewsView from '../Components/News/Item';
import { INewsEdit } from '../Shared/interfaces';
import NewsSource from '../Entities/NewsSource';
import NewsCategory from '../Entities/NewsCategory';
import News from '../Entities/News';
import Loading from '../Components/Loading';
import Routes from './../Shared/routes';
import CarsStoreObject from '../Entities/CarsStoreObject';


interface IProps {
    isLoading: boolean;
    isSourceLoading: boolean;
    isCategoryLoading: boolean;
    error: number;
    item: News;
    edit: (id: number, data: INewsEdit) => void;
    newsSourceList: NewsSource[];
    newsCategoryList: NewsCategory[];
    carsStoreObjectList: CarsStoreObject[];
    getStoreObjects: () => void;
    getNewsById: (id: number) => void;
    getNewsSource: () => void;
    getNewsCategory: () => void;
    history: any;
    match: any;
}

interface IState {
    error: number;
}


class EditNewsPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        if (!this.props.newsCategoryList.length) {
            await this.props.getNewsCategory();
        }
        if (!this.props.newsSourceList.length) {
            await this.props.getNewsSource();
        }
        if (!this.props.carsStoreObjectList.length) {
            await this.props.getStoreObjects();
        }
        const id = this.props.match.params.newsId;
        await this.props.getNewsById(id);
        if (!this.props.item) {
            this.props.history.push(Routes.NEWS_LIST);
        }
    }

    onSubmit = async (data: INewsEdit) => {
        if (data.endAt) {
            data.endAt = new Date(data.endAt);
        }
        await this.props.edit(this.props.item.getId(), data);
        if (!this.props.error) {
            this.props.history.push(Routes.NEWS_LIST)
        }
    }

    render() {
    
        if (!this.props.item || (this.props.isCategoryLoading && this.props.isSourceLoading)) {
            return <Loading />;
        }
        return (
            <EditNewsView
                post={this.props.item}
                error={this.props.error}
                carsStoreObjectList={this.props.carsStoreObjectList}
                categoryList={this.props.newsCategoryList}
                sourceList={this.props.newsSourceList}
                isLoading={this.props.isLoading}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        edit: (id: number, data: INewsEdit) => dispatch(edit(id, data)),
        getNewsById: (id: number) => dispatch(getOne(id)),
        getNewsCategory: () => dispatch(getNewsCategory()),
        getNewsSource: () => dispatch(getNewsSource()),
        getStoreObjects: () => dispatch(getStoreObjects())
    };
}

const mapStateToProps = (state: any) => {
    return {
        newsCategoryList: state.newsCategory.list,
        newsSourceList: state.newsSource.list,
        newsList: state.news.list,
        item: state.news.item,
        carsStoreObjectList: state.storeObject.list,
        isLoading: state.news.isLoading,
        isSourceLoading: state.newsSource.isLoading,
        isCategoryLoading: state.newsCategory.isLoading,
        error: state.newsSource.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EditNewsPage);
