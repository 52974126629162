import moment from 'moment';
import { BaseEntity } from "./BaseEntity";
import Client from "./Client";
import { REQUEST_SERVICE_TITLE } from "../Shared/const";
import CarsStoreObject from './CarsStoreObject';

export default class Request extends BaseEntity {

    constructor(id: number, private type: number,  private client: Client, private status: number, private createdAt: Date, private service?: CarsStoreObject,) {
        super(id);
    }

    getType(): string {
        return REQUEST_SERVICE_TITLE[String(this.type)];
    }

    getStatus(): number {
        return this.status;
    }

    getRequestAt(): string {
        return moment(this.createdAt).format('DD.MM.YYYY');
    }

    getClient(): Client {
        return this.client;
    }

    getService(): CarsStoreObject | null {
        return this.service ?? null;
    }
}