import Api from '.';
import { IRequestResponse } from './index';

class LogApi extends Api {
    ENDPOINT = 'log/'

    getList(page: number = 1, clientId: number | undefined = undefined): Promise<IRequestResponse> {
        return this.get(this.ENDPOINT, { page, clientId });
    }
}

const logApi = new LogApi();

export default logApi;