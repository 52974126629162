import React from 'react';
import { Link } from 'react-router-dom';
import Routes from './../../Shared/routes';
import Loading from '../Loading';
import News from '../../Entities/News';
import Pagination from '../Pagination';
import CarsStoreObject from '../../Entities/CarsStoreObject';


interface IProps {
    pages: number;
    currentPage: number;
    list: News[];
    error: string;
    onLoadMore: (page: number) => void;
    remove: (newsCategoryId: number) => void;
    onAdd: () => void;
    storeObject?: CarsStoreObject | null;
    isLoading: boolean;
}

export default class NewsList extends React.Component<IProps> {

    onAdd = () => {
        this.props.onAdd();
    }

    renderItem = (item: News) => {
        return (
            <tr>
                <td>{item.getId()}</td>
                <td>{item.getTitle()}</td>
                <td>{item.getCategory().getTitle()}</td>
                <td>{item.getSource().getTitle()}</td>
                <td>
                    <Link to={`${Routes.NEWS_EDIT}${item.getId()}`}>Редактировать</Link><br />
                    <a href="#" onClick={() => this.props.remove(item.getId())}>Удалить</a><br />
                </td>
            </tr>
        )
    }

    onPage = (page: number) => {
        this.props.onLoadMore(page);
    }

    render() {
        const { isLoading, list, storeObject } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (
                <>
                    <div className="app-title">
                        <div>
                            <h1><i className="fa fa-th-list"></i> Новости</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="tile">
                              
                                    {storeObject ?
                                        <>
                                            <h2 className="tile-title">Салон: {storeObject?.title}</h2>
                                        </> : null}
                                    {/* <ul className="list-inline">
                                        <li className="app-search">
                                            <input className="app-search__input" type="search" placeholder="Search" />
                                            <button className="app-search__button"><i className="fa fa-search"></i></button>
                                        </li>
                                    </ul> */}
                            

                                <button
                                    onClick={this.onAdd}
                                    className="btn btn-primary"
                                    type="submit">Добавить новость</button>
                                <br />
                                <br />
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Название</th>
                                            <th>Категории</th>
                                            <th>Источник</th>
                                            <th>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map(this.renderItem)}
                                    </tbody>
                                </table>
                                <Pagination
                                    onPage={this.onPage}
                                    pages={this.props.pages}
                                    current={this.props.currentPage} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-6">
                        
                    </div> */}
                </>
            )
        }
    }
}