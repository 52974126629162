import Api from '.';
import { IRequestResponse } from './index';

class Client extends Api {
    ENDPOINT = 'clients/'

    getList(page: number = 1): Promise<IRequestResponse> {
        return this.get(this.ENDPOINT, { page });
    }

    getById(id: number): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}${id}`);
    }
}

const client = new Client();

export default client;