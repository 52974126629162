import React from 'react';
import Loading from '../Loading';
import NewsCategory from '../../Entities/NewsCategory';
import NewsSource from '../../Entities/NewsSource';
import News from '../../Entities/News';
import { STATIC_FILE_URL } from './../../config';
import CarsStoreObject from '../../Entities/CarsStoreObject';
import { INewsEdit } from '../../Shared/interfaces';
import { removeEmptyObjectValues } from '../../Shared/utils';
import { ERROR_MESSAGES } from '../../Shared/errors';


interface IState {
    title: string;
    body: string;
    sourceId: number;
    categoryId: number;
    storeObjectId: number;
    image: any;
    imageUrl: string;
    endAt: string;
    isVisible: boolean;
}

interface IProps {
    post?: News;
    categoryList: NewsCategory[];
    sourceList: NewsSource[];
    carsStoreObjectList: CarsStoreObject[];
    error: number | null;
    onSubmit: (data: INewsEdit) => void;
    isLoading: boolean;
}

export default class ItemNews extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        if (props.post) {
            let storeObjectId = props.post.getCarsStoreObject()?.getId();
            this.state = {
                title: props.post.getTitle(),
                body: props.post.getBody(),
                storeObjectId: storeObjectId ? storeObjectId : 0,
                sourceId: props.post.getSource().getId(),
                categoryId: props.post.getCategory().getId(),
                isVisible: props.post.getVisibility(),
                endAt: props.post.getEndAt(),
                image: null,
                imageUrl: STATIC_FILE_URL + props.post.getImage()
            }
        }
        else {
            this.state = {
                title: '',
                body: '',
                imageUrl: '',
                sourceId: props.sourceList[0]?.getId(),
                categoryId: props.categoryList[0]?.getId(),
                storeObjectId: 0,
                image: null,
                endAt: '',
                isVisible: true
            }
        }


    }

    componentDidUpdate(oldProps: IProps) {
        if (this.props.post && !oldProps.post) {
            let storeObjectId = this.props.post.getCarsStoreObject()?.getId();
            this.setState({
                title: this.props.post.getTitle(),
                body: this.props.post.getBody(),
                storeObjectId: storeObjectId ? storeObjectId : 0,
                sourceId: this.props.post.getSource().getId(),
                categoryId: this.props.post.getCategory().getId(),
                isVisible: this.props.post.getVisibility(),
                endAt: this.props.post.getEndAt(),
                imageUrl: STATIC_FILE_URL + this.props.post.getImage()
            });
        }
    }

    handleChangeTitle = (event: any) => {
        const target = event.target;
        this.setState({
            title: target.value
        });
    }

    handleVisibilityChange = (event: any) => {
        const target = event.target;
        this.setState({
            isVisible: !!Number(target.value)
        });
    }

    handleCategoryChange = (event: any) => {
        const target = event.target;
        this.setState({
            categoryId: Number(target.value)
        });
    }

    handleChangeStoreObject = (event: any) => {
        const target = event.target;
        this.setState({
            storeObjectId: Number(target.value)
        });
    }

    handleSourceChange = (event: any) => {
        const target = event.target;
        this.setState({
            sourceId: Number(target.value)
        });
    }

    handleBodyChange = (event: any) => {
        const target = event.target;
        this.setState({
            body: target.value
        });
    }

    handleImageChange = (event: any) => {
        const file = event.target.files[0];
        this.setState({
            image: file,
            imageUrl: URL.createObjectURL(file)
        });
    }

    handleEndAtChange = (event: any) => {
        this.setState({
            endAt: event.target.value
        })
    }

    onSubmit = () => {
        const data = removeEmptyObjectValues(this.state);
        data.isVisible = this.state.isVisible;
        this.props.onSubmit(data);
    }

    renderSourceOption = (source: NewsSource) => {
        return (
            <option
                value={source.getId()}
                selected={source.getId() == this.state.sourceId}>
                {source.getTitle()}
            </option>
        )
    }

    renderCategoryOption = (category: NewsCategory) => {
        return (
            <option
                value={category.getId()}
                selected={category.getId() == this.state.categoryId}>
                {category.getTitle()}
            </option>
        )
    }

    renderCarsStoreObjectOption = (storeObject: CarsStoreObject) => {
        return (
            <option
                value={storeObject.getId()}
                selected={storeObject.getId() == this.state.storeObjectId}>
                {storeObject.title}
            </option>
        )
    }

    render() {
        const { isLoading, error, sourceList, carsStoreObjectList, categoryList } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (<div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-lg-6">
                                {error ?
                                    <p>{ERROR_MESSAGES[error]}</p> : null}
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Название</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.title}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeTitle}
                                            placeholder="Введите название" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleTextarea">Текст</label>
                                        <textarea
                                            value={this.state.body}
                                            onChange={this.handleBodyChange}
                                            className="form-control"
                                            id="exampleTextarea"
                                            rows={3}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Окончание (необязательно)</label>
                                        <input
                                            className="form-control"
                                            name="endAt"
                                            value={this.state.endAt}
                                            id="exampleInputEmail1"
                                            type="date"
                                            onChange={this.handleEndAtChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleSelect2">Источник</label>
                                        <select onChange={this.handleSourceChange} className="form-control" id="exampleSelect2" >
                                            {sourceList.map(this.renderSourceOption)}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleSelect2">Категории</label>
                                        <select onChange={this.handleCategoryChange} className="form-control" id="exampleSelect2" >
                                            {categoryList.map(this.renderCategoryOption)}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleSelect2">Салон</label>
                                        <select onChange={this.handleChangeStoreObject} className="form-control" id="exampleSelect2" >
                                            <option
                                                value={0}
                                                selected={0 == this.state.storeObjectId}>
                                                Не выбрано
                                            </option>
                                            {carsStoreObjectList.map(this.renderCarsStoreObjectOption)}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputFile">Изображение</label>
                                        <input onChange={this.handleImageChange}
                                            className="form-control-file"
                                            id="exampleInputFile" type="file" aria-describedby="fileHelp" />

                                        {this.state.imageUrl ?
                                            <img className="news-image" src={this.state.imageUrl} /> : null}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleSelect2">Видимость</label>
                                        <select
                                            onChange={this.handleVisibilityChange}
                                            className="form-control" id="exampleSelect2" >
                                            <option
                                                selected={this.state.isVisible}
                                                value={1}>
                                                Активно
                                            </option>
                                            <option
                                                selected={!this.state.isVisible}
                                                value={0}>
                                                Скрыто
                                            </option>
                                        </select>
                                    </div>
                                </form>
                                <div className="tile-footer">
                                    <button
                                        onClick={this.onSubmit}
                                        className="btn btn-primary"
                                        type="submit">Сохранить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}