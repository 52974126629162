import { SET_LIST, SET_ERROR } from '../Actions/request';
import Request from '../Entities/Request';

interface IState {
    list: Request[],
    error: string | null;
    currentPage: number;
    pages: number;
    isLoading: boolean;
};

const defaultState = {
    list: [],
    error: null,
    isLoading: false,
    pages: 0,
    currentPage: 0
};

export default function request(state: IState = defaultState, action: any) {
    switch (action.type) {
        case SET_LIST:
            return {
                ...state,
                list: action.list,
                pages: action.pages,
                currentPage: action.currentPage,
                isLoading: false
            };
        case SET_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        }
        default:
            return state;
    }
}
