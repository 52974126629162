import { BaseEntity } from "./BaseEntity";

export default class NewsSource extends BaseEntity {
    private title: string;
    private link: string;
    private status: number;

    constructor(id: number, title: string, link: string = '', status: number = -1) {
        super(id);
        this.title = title;
        this.link = link;
        this.status = status;
    }

    getTitle(): string {
        return this.title;
    }

    getLink(): string {
        return this.link;
    }

    getStatus(): number  {
        return this.status;
    }
}