import React from 'react';
import Loading from '../Loading';
import { ERROR_MESSAGES } from '../../Shared/errors';
import { IStoreObjectCategoryEdit } from '../../Shared/interfaces';
import { StoreObjectCategory } from '../../Entities/StoreObjectCategory';

interface IState {
    name: string;
    isVisible: boolean;
}

interface IProps {
    storeObjectCategory?: StoreObjectCategory;
    error: number | null;
    onSubmit: (data: IStoreObjectCategoryEdit) => void;
    isLoading: boolean;
}

export default class ItemOperationCategory extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            name: '',
            isVisible: false,
        }
    }

    componentDidUpdate(oldProps: IProps) {
      console.log( this.props.storeObjectCategory);
        if (!oldProps.storeObjectCategory && this.props.storeObjectCategory) {
            this.setState({
                name: this.props.storeObjectCategory.name,
                isVisible: this.props.storeObjectCategory.isVisible,
            });
        }
    }

    handleChangeName = (event: any) => {
        const target = event.target;
        this.setState({
            name: target.value
        });
    }

    handleChangeVisibality = (event: any) => {
      const target = event.target;
      this.setState({
          isVisible: !this.state.isVisible
      });
    }


    onSubmit = () => {
      console.log(this.state);
        this.props.onSubmit(this.state);
    }

    render() {
        const { isLoading, error } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (<div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-lg-6">
                                {error ?
                                    <p>{ERROR_MESSAGES[error]}</p> : null}
                                <form>

                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Имя</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.name}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeName}
                                            placeholder="Введите название" />
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                checked={this.state.isVisible}
                                                onChange={this.handleChangeVisibality}
                                                className="form-check-input"
                                                type="checkbox" />Активно в приложении
                                        </label>
                                    </div>
                                </form>
                                <div className="tile-footer">
                                    <button
                                        onClick={() => this.onSubmit()}
                                        className="btn btn-primary"
                                        type="submit">Сохранить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}