import React from 'react';
import { connect } from 'react-redux';
import { getList } from '../Actions/client';
import ClientListView from '../Components/Client/List';
import Client from '../Entities/Client';

interface IProps {
    isLoading: boolean;
    error: string;
    list: Client[];
    currentPage: number;
    pages: number;
    getList: (page?: number) => void;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class ClientListPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        this.props.getList();
    }

    onLoadMore = (page: number) => {
        this.props.getList(page);
    }

    render() {
        const { error, currentPage, pages, isLoading, list } = this.props;
        return (
            <ClientListView
                currentPage={currentPage}
                pages={pages}
                error={error}
                isLoading={isLoading}
                list={list}
                onLoadMore={this.onLoadMore}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getList: (page?: number) => dispatch(getList(page))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.client.isLoading,
        list: state.client.list,
        error: state.client.error,
        currentPage: state.client.currentPage,
        pages: state.client.pages
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(ClientListPage);
