import Api from '.';
import { IRequestResponse } from './index';
import { IStoreObjectEdit } from '../Shared/interfaces';

class StoreObject extends Api {
    ENDPOINT = 'cars-store-object';


    edit(id: number, data: IStoreObjectEdit) {
      console.log(data);
        return this.put(`${this.ENDPOINT}/${id}`, data);
    }

    getList(page: number = 1): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`, {
            page
        });
    }
}

const storeObject = new StoreObject();

export default storeObject;