import { plainToClass } from 'class-transformer';
import StoreObjectCategoryApi from '../Api/store-object-category';
import { StoreObjectCategory } from '../Entities/StoreObjectCategory';
import { IStoreObjectCategoryEdit } from '../Shared/interfaces';

export const SET_LIST = 'SET_LIST_STORE_OBJECT_CATEGORY';
export const SET_ERROR = 'SET_ERROR_STORE_OBJECT_CATEGORY';
export const SET_ITEM = 'SET_ITEM_STORE_OBJECT_CATEGORY';
export const SET_LOADING = 'SET_LOADING_STORE_OBJECT_CATEGORY';


export function getList() {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await StoreObjectCategoryApi.getList();
        if (result) {
            return dispatch({
                type: SET_LIST,
                list: data.map((item: any)=>plainToClass(StoreObjectCategory, item))
            });
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
    }
}

export function create(operation: IStoreObjectCategoryEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { error, result } = await StoreObjectCategoryApi.create(operation);
        if (result) {
            return dispatch(getList());
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
    }
}

export function edit(id: number, operation: IStoreObjectCategoryEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await StoreObjectCategoryApi.edit(id, operation);
        if (result) {
            return dispatch(getList());
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
    }
}

export function getOne(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await StoreObjectCategoryApi.getOne(id);
        if (result) {
            return dispatch({
                type: SET_ITEM,
                item: plainToClass(StoreObjectCategory, data)
            });
        }
    }
}



export function remove(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await StoreObjectCategoryApi.remove(id);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList());
    }
}