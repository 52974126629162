import React from 'react';
import { connect } from 'react-redux';
import { edit, getList } from '../Actions/store-object-category';
import Routes from '../Shared/routes';
import EditStoreObjectEdit from '../Components/StoreObjectCategory/Item';
import { IStoreObjectCategoryEdit } from '../Shared/interfaces';
import Loading from '../Components/Loading';
import { StoreObjectCategory } from '../Entities/StoreObjectCategory';


interface IProps {
    isLoading: boolean;
    error: number;
    edit: (id: number, data: IStoreObjectCategoryEdit) => void;
    getList: () => void;
    list: StoreObjectCategory[];
    match: any;
    history: any;
}

interface IState {
    error: number | null;
}

class EditStoreObjectCategory extends React.Component<IProps, IState> {
  storeObjectCategory: StoreObjectCategory | undefined;

  async componentDidMount() {
      const id = Number(this.props.match.params.storeObjectCategoryId);

      if (!this.props.list.length) {
          await this.props.getList();
      }
      this.storeObjectCategory = this.props.list.find((s: StoreObjectCategory) => s.getId() === id);
      if (!this.storeObjectCategory) {
          this.props.history.goBack();
      }
      else {
          this.forceUpdate();
      }
  }

  onSubmit = async (data: IStoreObjectCategoryEdit) => {
      if (this.storeObjectCategory?.getId()) {
        console.log(data);
          await this.props.edit(this.storeObjectCategory?.getId(), data);
          if (!this.props.error) {
              this.props.history.push(Routes.STORE_OBJECT_CATEGORY_LIST)
          }
      }
  }

    render() {
      console.log(this.storeObjectCategory);
        if (this.props.isLoading) {
            return <Loading/>;
        }
        return (
            <EditStoreObjectEdit
              storeObjectCategory={this.storeObjectCategory}
                error={this.props.error}
                isLoading={this.props.isLoading}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        edit: (id: number, data: IStoreObjectCategoryEdit) => dispatch(edit(id, data)),
        getList: () => dispatch(getList()),
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.storeObjectCategory.isLoading,
        list: state.storeObjectCategory.list,
        error: state.storeObjectCategory.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EditStoreObjectCategory);
