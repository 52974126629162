import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loading from './Loading';

interface IProps {
	onPolicySubmit: (text: string) => void;
	policy: string;
	onUserAgreegmentSubmit: (text: string) => void;
	userAgreegment: string;
	isLoading: boolean;
}

interface IState {
	policy: EditorState;
	userAgreegment: EditorState;
}

export default class Privacy extends React.Component<IProps, IState> {

	state = {
		policy: EditorState.createEmpty(),
		userAgreegment: EditorState.createEmpty()
	}

	componentDidUpdate(oldProps: IProps) {
		let userAgreegment = this.state.userAgreegment;
		let policy = this.state.policy;

		if (this.props.userAgreegment && !oldProps.userAgreegment ||
			this.props.policy && !oldProps.policy) {
			userAgreegment = this.createParam(this.props.userAgreegment);
			policy = this.createParam(this.props.policy);
			this.setState({
				userAgreegment,
				policy
			});
		}
	}

	createParam = (html: string): EditorState => {
		const contentBlock = htmlToDraft(html);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			return EditorState.createWithContent(contentState);
		}
		return EditorState.createEmpty();
	}

	convertToHtml = (data: EditorState): string => {
		return draftToHtml(convertToRaw(data.getCurrentContent()));
	}

	onPrivacyChange = (policy: any) => {
		this.setState({
			policy
		})
	}

	onUserAgreegmentChange = (userAgreegment: EditorState) => {
		this.setState({
			userAgreegment
		})
	}

	onUserAgreegmentSubmit = () => {
		this.props.onUserAgreegmentSubmit(this.convertToHtml(this.state.userAgreegment));
	}

	onPolicySubmit = () => {
		this.props.onPolicySubmit(this.convertToHtml(this.state.policy));
	}

	render() {
		if (this.props.isLoading) {
			return <Loading />;
		}
		return (
			<>
				<div className="app-title">
					<div>
						<h1><i className="fa fa-user"></i> Правовая информация</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-10">
						<div className="tile">
							<div className="row mb-4">
								<div className="col-6">
									<h2 className="page-header">Политика конфидициальности</h2>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<form>
										<Editor
											editorState={this.state.policy}
											toolbarClassName="toolbarClassName"
											wrapperClassName="wrapperClassName"
											editorClassName="editorClassName"
											onEditorStateChange={this.onPrivacyChange}
										/>
									</form>
									<div className="tile-footer">
										<button
											onClick={this.onPolicySubmit}
											className="btn btn-primary"
											type="submit">Сохранить</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-10">
						<div className="tile">
							<div className="row mb-4">
								<div className="col-6">
									<h2 className="page-header">Пользовательское соглашение</h2>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<form>
										<Editor
											editorState={this.state.userAgreegment}
											toolbarClassName="toolbarClassName"
											wrapperClassName="wrapperClassName"
											editorClassName="editorClassName"
											onEditorStateChange={this.onUserAgreegmentChange}
										/>
									</form>
									<div className="tile-footer">
										<button
											onClick={this.onUserAgreegmentSubmit}
											className="btn btn-primary"
											type="submit">Сохранить</button>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</>
		)
	}
}