import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import Client from '../../Entities/Client';
import Pagination from '../Pagination';
import Routes from './../../Shared/routes';


interface IProps {
	pages: number;
	currentPage: number;
	list: Client[];
	error: string;
	onLoadMore: (page: number) => void;
	isLoading: boolean;
}

export default class ClientList extends React.Component<IProps> {

	renderItem = (item: Client) => {
		return (
			<tr>
				<td>{item.getId()}</td>
				<td>{item.getName()}</td>
				<td>{item.getPhoneNumber()}</td>
				<td>{item.getEmail()}</td>
				<td>
					<Link to={`${Routes.CLIENT_ITEM}${item.getId()}`}>Просмотр</Link><br />
					<Link to={`${Routes.REQUEST_LIST_BY_CLIENT}${item.getId()}`}>Запросы</Link><br />
					<Link to={`${Routes.NOTIFICATION_LIST}${item.getId()}`}>Оповещения</Link><br />
					<Link to={`${Routes.APP_ERROR_LOG}${item.getId()}`}>Лог ошибок</Link>
				</td>
			</tr>
		)
	}

	onPage = (page: number) => {
		this.props.onLoadMore(page);
	}

	render() {
		const { isLoading, list } = this.props;
		if (isLoading) {
			return <Loading />;
		}
		else {
			return (
				<>

					<div className="app-title">
						<div>
							<h1><i className="fa fa-th-list"></i> Клиенты</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="tile">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>#</th>
											<th>Имя и фамилия</th>
											<th>Номер телефона</th>
											<th>Почта</th>
											<th>Действия</th>
										</tr>
									</thead>
									<tbody>
										{list.map(this.renderItem)}
									</tbody>
								</table>
								<Pagination
									onPage={this.onPage}
									pages={this.props.pages}
									current={this.props.currentPage} />
							</div>
						</div>
					</div>
				</>
			)
		}
	}
}