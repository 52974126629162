import { SET_CURRENT, SET_LIST, SET_ERROR, SET_LOADING } from '../Actions/managers';
import Manager from '../Entities/Manager';

interface TState {
    list: Manager[],
    current: any;
    error: string | null;
};

const defaultState = {
    list: [],
    error: null,
    current: null
};

export default function channel(state: TState = defaultState, action: any) {
    switch (action.type) {
        case SET_LIST:
            return {
                ...state,
                list: action.list,
                isLoading: false
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case SET_CURRENT:
            return {
                ...state,
                current: action.data,
                isLoading: false
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        default:
            return state;
    }
}
