import React from 'react';
import { connect } from 'react-redux';
import { get, save } from './../Actions/updateApp';
import { IUpdateApk } from '../Shared/interfaces';
import UploadApk from './../Components/UploadApk';
import Loading from '../Components/Loading';

interface IProps {
	isLoading: boolean;
	error: number;
	data: IUpdateApk;
	get: () => void;
	save: (data: IUpdateApk) => void;
}

class UploadApkPage extends React.Component<IProps> {

	componentDidMount() {
		this.props.get();
	}

	onSubmit = (data: IUpdateApk) => {
		this.props.save(data);
	}

	render() {
		if (!this.props.data || this.props.isLoading) {
			return <Loading />;
		}
		return (
			<UploadApk
				onSubmit={this.onSubmit}
				force={this.props.data.force}
				version={this.props.data.version} />
		);
	}
}

function bindAction(dispatch: any) {
	return {
		get: () => dispatch(get()),
		save: (data: IUpdateApk) => dispatch(save(data)),
	};
}

const mapStateToProps = (state: any) => {
	return {
		isLoading: state.uploadApp.isLoading,
		data: state.uploadApp.data,
		error: state.uploadApp.error,
	}
}

export default connect(
	mapStateToProps,
	bindAction
)(UploadApkPage);
