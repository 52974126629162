import ManagerAPI from './../Api/manager';
import Manager from '../Entities/Manager';
import { mapManager } from '../Shared/mappers';
import { IManagerEdit } from '../Shared/interfaces';

export const SET_CURRENT = 'SET_CURRENT_MANAGER';
export const SET_LIST = 'SET_LIST_MANAGER';
export const SET_ERROR = 'SET_ERROR_MANAGER';
export const SET_LOADING = 'SET_LOADING_MANAGER';

export function getCurrent() {
    return async (dispatch: any) => {
        const { result, data, error } = await ManagerAPI.getCurrent();
        if (result) {
            return dispatch({
                type: SET_CURRENT,
                data: new Manager(data.id, data.firstName, data.lastName, data.email, data.role)
            });
        }

    }
}

export function getList() {
    return async (dispatch: any) => {
        const { result, error, data } = await ManagerAPI.getList();
        if (result) {
            return dispatch({
                type: SET_LIST,
                list: mapManager(data)
            });
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
    }
}

export function create(manager: IManagerEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });

        const { error, result } = await ManagerAPI.create(manager);
        if (result) {
            return dispatch(getList());
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
    }
}

export function edit(id: number, manager: IManagerEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });

        const { result, error } = await ManagerAPI.edit(id, manager);
        if (result) {
            return dispatch(getList());
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
    }
}

export function logout() {

}


export function remove(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await ManagerAPI.remove(id);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList());
    }
}