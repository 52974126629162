import React from 'react';
import Loading from '../Loading';
import NewsCategory from '../../Entities/NewsCategory';
import { ERROR_MESSAGES } from '../../Shared/errors';

interface IState {
    title: string;
    color: string;
}

interface IProps {
    newsCategory?: NewsCategory;
    error: number | null;
    onSubmit: (title: string, color: string) => void;
    isLoading: boolean;
}

export default class ItemNewsCategory extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            title: '',
            color: ''
        }
    }

    componentDidUpdate(oldProps: IProps) {
        if (this.props.newsCategory && !oldProps.newsCategory) {
            let title = '', color = '';
            const newsCategory: NewsCategory | undefined = this.props.newsCategory;
            title = newsCategory.getTitle();
            color = newsCategory.getColor();
            this.setState({
                title,
                color
            });
        }
    }

    handleChangeTitle = (event: any) => {
        const target = event.target;
        this.setState({
            title: target.value
        });
    }

    handleChangeColor = (event: any) => {
        const target = event.target;
        this.setState({
            color: target.value
        });
    }


    onSubmit = () => {
        const { title, color } = this.state;
        if (title && color) {
            this.props.onSubmit(this.state.title, this.state.color);
        }
    }

    render() {
        const { isLoading, error } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (<div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-lg-6">
                                {error ?
                                    <p>{ERROR_MESSAGES[error]}</p> : null}
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Название</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.title}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeTitle}
                                            placeholder="Введите название" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Цвет</label>
                                        <input
                                            value={this.state.color}
                                            className="form-control"
                                            name="link"
                                            placeholder="Вставьте ссылку"
                                            onChange={this.handleChangeColor}
                                            id="exampleInputEmail1" type="text" />
                                    </div>
                                </form>
                                <div className="tile-footer">
                                    <button
                                        onClick={this.onSubmit}
                                        className="btn btn-primary"
                                        type="submit">Сохранить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}