import UpdateAppApi from '../Api/updateApp';
import { IUpdateApk } from '../Shared/interfaces';
import { mapLog } from '../Shared/mappers';

export const SET_ERROR = 'SET_UPDATE_APP_ERROR';
export const SET_DATA = 'SET_UPDATE_APP_DATA';
export const SET_LOADING = 'SET_UPDATE_APP_LOADING';


export function get() {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await UpdateAppApi.getData();
        console.log(data);
        if (result) {
            return dispatch({
                type: SET_DATA,
                data
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function save(updateData: IUpdateApk) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });

        let error = null;
        if (updateData.file) {
            const uploadResponse = await UpdateAppApi.upload(updateData.file);
            error = uploadResponse.error;
            delete updateData.file;
        }
        if (!error) {
            const { result, error: saveError, data } = await UpdateAppApi.saveData(updateData);
            error = saveError;
            if (result) {
                return dispatch({
                    type: SET_DATA,
                    data: updateData
                });
            }
        }

        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}
