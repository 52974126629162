import NewsSourceApi from './../Api/newsSource';
import { mapNewsSources } from '../Shared/mappers';

export const SET_ERROR = 'SET_SOURCE_ERROR';
export const SET_LIST = 'SET_SOURCE_LIST';
export const SET_LOADING = 'SET_SOURCE_LOADING';

export function create(title: string, link: string, status: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await NewsSourceApi.create(title, link, status);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())
    }
}

export function edit(id: number, title: string, link: string, status: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await NewsSourceApi.edit(id, title, link, status);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())

    }
}

export function getList(page: number = 1) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await NewsSourceApi.getList(page);
        if (result) {
            return dispatch({
                type: SET_LIST,
                data: mapNewsSources(data)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function remove(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await NewsSourceApi.remove(id);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList());
    }
}