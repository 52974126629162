import React from 'react';
import Loading from '../Loading';
import Manager from '../../Entities/Manager';
import { ERROR_MESSAGES } from '../../Shared/errors';
import { IManagerEdit } from '../../Shared/interfaces';
import { removeEmptyObjectValues } from '../../Shared/utils';

interface IState {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

interface IProps {
    manager?: Manager;
    error: number | null;
    onSubmit: (data: IManagerEdit) => void;
    isLoading: boolean;
}

export default class ItemNews extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
        }
    }

    componentDidUpdate(oldProps: IProps) {
        if (this.props.manager && !oldProps.manager) {
            this.setState({
                firstName: this.props.manager.firstName,
                lastName: this.props.manager.lastName,
                email: this.props.manager.email
            });
        }
    }

    handleChangeFirstName = (event: any) => {
        const target = event.target;
        this.setState({
            firstName: target.value
        });
    }

    handleChangeLastName = (event: any) => {
        const target = event.target;
        this.setState({
            lastName: target.value
        });
    }

    handleChangeEmail = (event: any) => {
        const target = event.target;
        this.setState({
            email: target.value
        });
    }

    handleChangePassword = (event: any) => {
        const target = event.target;
        this.setState({
            password: target.value
        });
    }

    onSubmit = () => {
        this.props.onSubmit(removeEmptyObjectValues(this.state));
    }

    render() {
        const { isLoading, error } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (<div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-lg-6">
                                {error ?
                                    <p>{ERROR_MESSAGES[error]}</p> : null}
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Имя</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.firstName}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeFirstName}
                                            placeholder="Введите имя" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Фамилия</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.lastName}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeLastName}
                                            placeholder="Введите фамилия" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleTextarea">Почта</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.email}
                                            id="exampleInputEmail1"
                                            type="email"
                                            onChange={this.handleChangeEmail}
                                            placeholder="Введите почту" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleTextarea">Пароль</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.password}
                                            id="exampleInputEmail1"
                                            type="password"
                                            onChange={this.handleChangePassword}
                                            placeholder="Введите пароль" />
                                    </div>
                                </form>
                                <div className="tile-footer">
                                    <button
                                        onClick={this.onSubmit}
                                        className="btn btn-primary"
                                        type="submit">Сохранить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}