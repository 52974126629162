import Api from '.';
import { IRequestResponse } from './index';

class NewsCategory extends Api {
    ENDPOINT = 'news-category';

    create(title: string, color: string) {
        return this.post(this.ENDPOINT, {
            title,
            color
        });
    }

    edit(id: number, title: string, color: string) {
        return this.put(`${this.ENDPOINT}/${id}`, {
            title,
            color
        });
    }

    remove(id: number) {
        return this.delete(`${this.ENDPOINT}/${id}`);
    }

    getList(page: number): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`, {
            page
        });
    }
}

const newsCategory = new NewsCategory();

export default newsCategory;