import React from 'react';
import { connect } from 'react-redux';
import { getOne } from '../Actions/client';
import ClientItemView from '../Components/Client/Item';
import Client from '../Entities/Client';
import Routes from './../Shared/routes';

interface IProps {
    isLoading: boolean;
    error: string;
    item: Client;
    list: Client[];
    currentPage: number;
    pages: number;
    match: any;
    history: any;
    getOne: (clientId: number) => void;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class ClientListPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        const id = this.props.match.params.clientId;
        await this.props.getOne(id);
        if (!this.props.item) {
            this.props.history.push(Routes.CLIENT_LIST);
        }
    }


    render() {
        const { isLoading } = this.props;
        return (
            <ClientItemView
                client={this.props.item}
                isLoading={isLoading}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getOne: (clientId: number) => dispatch(getOne(clientId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.client.isLoading,
        list: state.client.list,
        item: state.client.item,
        error: state.client.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(ClientListPage);
