import Api from '.';
import { IRequestResponse } from './index';
import { IManagerEdit } from '../Shared/interfaces';

class Manager extends Api {
    ENDPOINT = 'managers/'

    async login(email: string, password: string) {
        const response = await this.post('auth/manager/login', { email, password });
        if (response.result) {
            super.saveToken(response.data.accessToken);
        }
        return response;
    }

    create(data: IManagerEdit) {
        return this.post(this.ENDPOINT, data);
    }

    edit(id: number, data: IManagerEdit) {
        return this.put(`${this.ENDPOINT}${id}`, data);
    }

    remove(id: number) {
        return this.delete(`${this.ENDPOINT}${id}`);
    }

    getCurrent(): Promise<IRequestResponse> {
        return this.get('auth/manager');
    }

    getList(): Promise<IRequestResponse> {
        return this.get(this.ENDPOINT);
    }
}

const manager = new Manager();

export default manager;