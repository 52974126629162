import { SET_LIST, SET_ERROR, SET_LOADING, SET_ITEM } from '../Actions/store-object-category';
import { StoreObjectCategory } from '../Entities/StoreObjectCategory';

interface TState {
    list: StoreObjectCategory[],
    item: StoreObjectCategory | null,
    error: string | null;
};

const defaultState = {
    list: [],
    error: null,
    item: null
};

export default function storeObjectCategory(state: TState = defaultState, action: any) {
    switch (action.type) {
        case SET_LIST:
            return {
                ...state,
                list: action.list,
                isLoading: false
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        case SET_ITEM: 
            return {
                ...state,
                item: action.item,
                isLoading: false
            }
        default:
            return state;
    }
}
