import { combineReducers } from 'redux';
import newsSource from './newsSource';
import newsCategory from './newsCategory';
import storeObject from './storeObject';
import news from './news';
import manager from './manager';
import log from './log';
import client from './client';
import staticPage from './staticPage';
import request from './request';
import uploadApp from './uploadApp';
import privacy from './privacy';
import notification from './notification';
import storeObjectCategory from './store-object-category';

export default combineReducers({
    newsSource,
    newsCategory,
    request,
    storeObject,
    news,
    manager,
    notification,
    privacy,
    client,
    staticPage,
    log,
    uploadApp,
    storeObjectCategory
});
