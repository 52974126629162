import React from 'react';
import { connect } from 'react-redux';
import { getData, savePolicy, saveUserAgreegment } from './../Actions/privacy';
import Privacy from '../Components/Privacy';

interface IProps {
	isLoading: boolean;
	error: string;
	getData: () => void;
	savePolicy: (text: string) => void;
	saveUserAgreegment: (text: string) => void;
	userAgreegment: string;
	policy: string;
}

class PrivacyPage extends React.Component<IProps> {

	componentDidMount() {
		this.props.getData();
	}

	onPolicySubmit = (data: string) => {
		this.props.savePolicy(data);
	}

	onUserAgreegment = (data: string) => {
		this.props.saveUserAgreegment(data);
	}

	render() {
		const { error, isLoading, userAgreegment, policy } = this.props;
		return (
			<Privacy
				isLoading={isLoading}
				onPolicySubmit={this.onPolicySubmit}
				onUserAgreegmentSubmit={this.onUserAgreegment}
				policy={policy}
				userAgreegment={userAgreegment} />
		);
	}
}

function bindAction(dispatch: any) {
	return {
		getData: () => dispatch(getData()),
		savePolicy: (data: string) => dispatch(savePolicy(data)),
		saveUserAgreegment: (data: string) => dispatch(saveUserAgreegment(data))
	};
}

const mapStateToProps = (state: any) => {
	return {
		isLoading: state.privacy.isLoading,
		userAgreegment: state.privacy.userAgreegment,
		policy: state.privacy.policy,
		error: state.privacy.error
	}
}

export default connect(
	mapStateToProps,
	bindAction
)(PrivacyPage);
