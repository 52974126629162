import React from 'react';
import Loading from '../Loading';
import { Log } from '../../Entities/Log';
import Pagination from '../Pagination';
import { Link } from 'react-router-dom';
import Routes from '../../Shared/routes';


interface IProps {
    pages: number;
    currentPage: number;
    list: Log[];
    error: string;
    onLoadMore: (page: number) => void;
    clientId?: number;
    isLoading: boolean;
    onClient: (clientId: number) => void;
}

export default class NewsList extends React.Component<IProps> {

    renderItem = (item: Log) => {
        return (
            <tr>
                <td>{item.getId()}</td>
                <td>{item.getType()}</td>
                <td>{item.message}</td>
                {!this.props.clientId ?
                    <td>
                        <Link to={'#'} onClick={() => this.props.onClient(item.clientId)}>
                            {item.clientId}
                        </Link>
                    </td> : null}
                <td>{item.getCreatedAt()}</td>
            </tr>
        )
    }

    onPage = (page: number) => {
        this.props.onLoadMore(page);
    }

    render() {
        const { isLoading, list, clientId } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (
                <>
                    <div className="app-title">
                        <div>
                            <h1><i className="fa fa-th-list"></i> Лог ошибок приложения</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tile">
                                {clientId ?
                                    <>
                                        <h2 className="tile-title">Id клиента: {clientId}</h2>
                                    </> : null}
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Тип</th>
                                            <th>Подробности</th>
                                            {!clientId ?
                                                <th>Id клиента</th> : null}
                                            <th>Дата</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map(this.renderItem)}
                                    </tbody>
                                </table>
                                <Pagination
                                    onPage={this.onPage}
                                    pages={this.props.pages}
                                    current={this.props.currentPage} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-6">
                        
                    </div> */}
                </>
            )
        }
    }
}