import React from 'react';
import { connect } from 'react-redux';
import { getList } from '../Actions/log';
import { Log } from '../Entities/Log';
import LogListView from '../Components/Log/List';


interface IProps {
    isLoading: boolean;
    error: string;
    list: Log[];
    getList: (page?: number, clientId?: number) => void;
    remove: (nId: number) => void;
    currentPage: number;
    pages: number;
    history: any;
    match: any;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class LogListPage extends React.Component<IProps, IState> {
    clientId: number | undefined;

    componentDidMount() {
        this.clientId = this.props.match.params.clientId;
        this.props.getList(1, this.clientId);
    }

    onLoadMore = (page: number) => {
        this.props.getList(page, this.clientId);
    }

    onClient = (clientId: number) => {
        this.clientId = clientId;
        this.props.getList(1, this.clientId);
    }

    render() {
        const { error, isLoading, list, currentPage, pages } = this.props;
        return (
            <LogListView
                pages={pages}
                currentPage={currentPage}
                onLoadMore={this.onLoadMore}
                error={error}
                isLoading={isLoading}
                list={list}
                onClient={this.onClient}
                clientId={this.clientId}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getList: (page?: number, clientId?: number) => dispatch(getList(page, clientId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.log.isLoading,
        list: state.log.list,
        error: state.log.error,
        currentPage: state.log.currentPage,
        pages: state.log.pages
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(LogListPage);
