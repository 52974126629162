import React, { Component } from 'react';
import ManagerAPI from './../Api/manager';
import Loading from './../Components/Loading';
import Launch from './Launch';
import Login from './Login';

interface IProps {

}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

export default class Boot extends Component<IProps, IState> {
    state: IState;
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: true,
            isLogin: false
        };
    }
    async componentWillMount() {
        ManagerAPI.addFailedTokenValidationListener(this.onTokenFailed);
        ManagerAPI.addLogoutListener(this.onLogout)
        this.setState({
            isLoading: false,
            isLogin: !!ManagerAPI.getToken()
        });
    }

    onSuccess = () => {
        this.setState({
            isLogin: true
        })
    }

    onLogout = () => {
        this.setState({
            isLogin: false
        })
    }

    onTokenFailed = () => {
        this.setState({
            isLoading: false,
            isLogin: false
        })
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        if (this.state.isLogin) {
            return <Launch />;
        }
        return <Login onSuccess={this.onSuccess} />

    }
}

