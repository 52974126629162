import Api from '.';
import { IUpdateApk } from '../Shared/interfaces';
import { IRequestResponse } from './index';

class UpdateApp extends Api {
	ENDPOINT = 'update-app/'

	getData(): Promise<IRequestResponse> {
		return this.get(this.ENDPOINT);
	}

	saveData(data: IUpdateApk): Promise<IRequestResponse> {
		console.log('sds' , data);
		return this.put(this.ENDPOINT, data);
	}

	upload(file: any) {
		var formData = new FormData();
		formData.append("file", file);
		return this.patchMultipart(`${this.ENDPOINT}upload/`, formData);
	}
}

const updateApp = new UpdateApp();

export default updateApp;