import RequestAPI from './../Api/request';
import { mapRequest } from '../Shared/mappers';

export const SET_LIST = 'SET_REQUEST_LIST';
export const SET_ERROR = 'SET_REQUEST_ERROR';
export const SET_LOADING = 'SET_REQUEST_LOADING';

export function getList(page: number = 1) {
    return async (dispatch: any) => {
        const { result, data, error } = await RequestAPI.getList(page);
        if (result) {
            return dispatch({
                type: SET_LIST,
                list: mapRequest(data.list),
                currentPage: data.currentPage,
                pages: data.pages
            });
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            })
        }
    }
}

export function getListByClient(clientId: number, page: number = 1) {
    return async (dispatch: any) => {
        const { result, data, error } = await RequestAPI.getListByClient(clientId, page);
        if (result) {
            return dispatch({
                type: SET_LIST,
                list: mapRequest(data.list),
                currentPage: data.currentPage,
                pages: data.pages
            });
        }
        else {
            return dispatch({
                type: SET_ERROR,
                error
            })
        }
    }
}
