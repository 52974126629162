import React from 'react';
import Loading from './Loading';
import { ERROR_MESSAGES } from '../Shared/errors';
import { IUpdateApk } from '../Shared/interfaces';


interface IState {
	version: number;
	force: boolean;
	file: any;
}

interface IProps {
	version: number;
	force: boolean;
	onSubmit: (data: IUpdateApk) => void;
}

export default class ItemNews extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			version: props.version,
			force: props.force,
			file: null
		}
	}


	handleChangeVersion = (event: any) => {
		const target = event.target;
		this.setState({
			version: target.value
		});
	}

	handleChangeForce = (event: any) => {
		this.setState({
			force: !this.state.force
		});
	}

	handleFileChange = (event: any) => {
		const file = event.target.files[0];
		this.setState({
			file: file
		});
	}

	onSubmit = () => {
		this.props.onSubmit({
			version: Number(this.state.version),
			force: this.state.force,
			file: this.state.file
		});
	}

	render() {
		return (<div className="row">
			<div className="col-md-12">
				<div className="tile">
					<div className="row">
						<div className="col-lg-6">
							{/* {error ?
									<p>{ERROR_MESSAGES[error]}</p> : null} */}
							<form>
								<div className="form-group">
									<label htmlFor="exampleInputEmail1">Версия</label>
									<input
										className="form-control"
										name="version"
										value={this.state.version}
										id="exampleInputEmail1"
										type="number"

										onChange={this.handleChangeVersion}
										placeholder="Введите версию" />
								</div>
								<div className="form-group">
									<div className="form-check">
										<label className="form-check-label">
											<input checked={this.state.force} onChange={this.handleChangeForce} className="form-check-input" type="checkbox" />Форсировать обновление
                    						</label>
									</div>
								</div>
								<div className="form-group">
									<label htmlFor="exampleInputFile">АПК</label>
									<input onChange={this.handleFileChange}
										className="form-control-file"
										id="exampleInputFile" type="file" aria-describedby="fileHelp" />
								</div>
							</form>
							<div className="tile-footer">
								<button
									onClick={this.onSubmit}
									className="btn btn-primary"
									type="submit">Сохранить</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		)
		// }
	}
}