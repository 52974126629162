import Api from '.';
import { IRequestResponse } from './index';
import { INewsEdit } from '../Shared/interfaces';

class NewsCategory extends Api {
    ENDPOINT = 'news';

    create(data: INewsEdit) {
        return this.post(this.ENDPOINT, data);
    }

    edit(id: number, data: INewsEdit) {
        return this.put(`${this.ENDPOINT}/${id}`, data);
    }

    uploadImage(id: number, file: any) {
        var formData = new FormData();
        formData.append("file", file);
        return this.patchMultipart(`${this.ENDPOINT}/image/${id}`, formData);
    }

    remove(id: number) {
        return this.delete(`${this.ENDPOINT}/${id}`);
    }

    getList(page: number | null = null): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`, {
            page
        });
    }

    getById(id: number): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}/${id}`);
    }

    getListByStoreObject(carsStoreObjectId: number) {
        return this.get(`${this.ENDPOINT}`, { carsStoreObjectId });
    }
}

const newsCategory = new NewsCategory();

export default newsCategory;
