import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loading from './Loading';

interface IProps {
	onSubmit: (text: string, phoneNumber: string) => void;
	value: string;
	phoneNumber: string;
	isLoading: boolean;
}

interface IState {
	value: EditorState;
	phoneNumber: string;
}

export default class StaticTunning extends React.Component<IProps, IState> {

	state = {
		value: EditorState.createEmpty(),
		phoneNumber: ''
	}

	componentDidUpdate(oldProps: IProps) {
		if (this.props.value && !oldProps.value) {
			this.setState({
				value: this.createParam(this.props.value),
				phoneNumber: this.props.phoneNumber
			});
		}
	}

	createParam = (html: string): EditorState => {
		const contentBlock = htmlToDraft(html);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			return EditorState.createWithContent(contentState);
		}
		return EditorState.createEmpty();
	}

	convertToHtml = (data: EditorState): string => {
		return draftToHtml(convertToRaw(data.getCurrentContent()));
	}

	changePhoneNumber = (event: any) => {
		const target = event.target;
		this.setState({
			phoneNumber: target.value
		});
	}

	onChange = (value: EditorState) => {
		this.setState({
			value
		})
	}


	onSubmit = () => {
		this.props.onSubmit(this.convertToHtml(this.state.value), this.state.phoneNumber);
	}

	render() {
		if (this.props.isLoading) {
			return <Loading />;
		}
		return (
			<>
				<div className="app-title">
					<div>
						<h1><i className="fa fa-user"></i> Тюннинг</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="tile">
							<div className="row">
								<div className="col-lg-10">
									<form>
										<Editor
											editorState={this.state.value}
											toolbarClassName="toolbarClassName"
											wrapperClassName="wrapperClassName"
											editorClassName="editorClassName"
											onEditorStateChange={this.onChange}
										/>
										<div className="form-group">
											<label htmlFor="exampleInputEmail1">Номер телефона</label>
											<input
												className="form-control"
												name="chatChannelId"
												value={this.state.phoneNumber}
												id="exampleInputEmail1"
												type="text"
												onChange={this.changePhoneNumber}
												placeholder="Введите номер телефона" />
										</div>
									</form>
									<div className="tile-footer">
										<button
											onClick={this.onSubmit}
											className="btn btn-primary"
											type="submit">Сохранить</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}