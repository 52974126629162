import Api from '.';
import { IRequestResponse } from './index';

class Request extends Api {
    ENDPOINT = 'request-service';

    getList(page: number = 1): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`, {
            page
        });
    }

    getListByClient(clientId: number, page: number = 1): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`, {
            page,
            clientId
        });
    }
}

const newsCategory = new Request();

export default newsCategory;
