import { SET_ERROR, SET_LIST, SET_LOADING, SET_ITEM } from '../Actions/news';
import News from '../Entities/News';

interface IState {
    list: News[],
    item: News | null;
    currentPage: number,
    pages: number;
    error: string | null;
    isLoading: boolean;
};

const defaultState = {
    list: [],
    item: null,
    error: null,
    isLoading: false,
    pages: 0,
    currentPage: 0
};

export default function newsSource(state: IState = defaultState, action: any) {
    switch (action.type) {
        case SET_LIST:
            return {
                ...state,
                list: action.data,
                pages: action.pages,
                currentPage: action.currentPage,
                isLoading: false
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }

        case SET_ITEM:
            return {
                ...state,
                item: action.item,
                isLoading: false
            }
        default:
            return state;
    }
}
