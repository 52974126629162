import React from 'react';
import Pagination from '../Pagination';
import Loading from '../Loading';
import Request from '../../Entities/Request';


interface IProps {
    pages: number;
    currentPage: number;
    onLoadMore: (page: number) => void;
    list: Request[];
    error: string;
    isLoading: boolean;
}

export default class RequestList extends React.Component<IProps> {

    onPage = (page: number) => {
        this.props.onLoadMore(page);
    }

    renderItem = (item: Request) => {
        return (
            <tr>
                <td>{item.getId()}</td>
                <td>
                    {item.getClient().getName()}
                    <br/>
                    {item.getClient().getPhoneNumber()}
                    <br/>
                    {item.getClient().getEmail()}
                </td>
                <td>{item.getService()?.title}</td>
                <td>{item.getType()}</td>
                <td>{item.getStatus()}</td>
                <td>{item.getRequestAt()}</td>
                {/* <td>
                    <Link to={`${Routes.NEWS_SOURCE_EDIT}${item.getId()}`}>Редактировать</Link><br />
                </td> */}
            </tr>
        )
    }

    render() {
        const { isLoading, list } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (
                <>

                    <div className="app-title">
                        <div>
                            <h1><i className="fa fa-th-list"></i> Запросы</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10">
                            <div className="tile">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Клиент</th>
                                            <th>Сервис</th>
                                            <th>Тип</th>
                                            <th>Статус</th>
                                            <th>Время заявки</th>
                                            {/* <th>Действия</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map(this.renderItem)}
                                    </tbody>
                                </table>
                                <Pagination
                                    onPage={this.onPage}
                                    pages={this.props.pages}
                                    current={this.props.currentPage} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}