import Api from '.';
import { IRequestResponse } from './index';

class NewsSource extends Api {
    ENDPOINT = 'news-source';

    create(title: string, link: string, status: number) {
        return this.post(this.ENDPOINT, {
            title,
            link,
            status
        });
    }

    edit(id: number, title: string, link: string, status: number) {
        return this.put(`${this.ENDPOINT}/${id}`, {
            title,
            link,
            status
        });
    }

    remove(id: number) {
        return this.delete(`${this.ENDPOINT}/${id}`);
    }

    getList(page: number = 1): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`, {
            page
        });
    }
}

const newsSource = new NewsSource();

export default newsSource;