export default {
    HOME: '/',
    MANAGER_CREATE: '/manager/create',
    MANAGER_LIST: '/manager/list',
    MANAGER_EDIT: '/manager/edit/',
    REQUEST_LIST: '/request/list',
    NEWS_CREATE: '/news/create',
    NEWS_EDIT: '/news/edit/',
    NEWS_LIST: '/news/list',
    NOTIFICATION_LIST: '/notification/client/',
    NEWS_LIST_BY_STORE_OBJECT: `/news/storeObjects/`,
    CLIENT_ITEM: '/client/',
    CLIENT_LIST: '/client/list',
    NEWS_CATEGORY_CREATE: '/news/category/create',
    NEWS_CATEGORY_EDIT: '/news/category/edit/',
    NEWS_CATEGORY_LIST: '/news/category/list',
    NEWS_SOURCE_CREATE: '/news-source/create',
    NEWS_SOURCE_EDIT: '/news-source/edit/',
    NEWS_SOURCE_LIST: '/news-source/list',
    STORE_OBJECT_EDIT: '/storeObject/edit/',
    STORE_OBJECT_CREATE: '/storeObject/create',
    STORE_OBJECT_LIST: '/storeObject/list',
    STORE_OBJECT_CATEGORY_LIST: '/storeObject/category/list',
    STORE_OBJECT_CATEGORY_CREATE: '/storeObject/category/create',
    STORE_OBJECT_CATEGORY_EDIT: '/storeObject/category/edit',
    REQUEST_LIST_BY_CLIENT: '/request/client/',
    PRIVACY: '/privacy',
    EDIT_STATIC_TUNNING: '/static/tunning',
    EDIT_STATIC_ROAD_HELP: '/static/road-help',
    APP_ERROR_LOG: '/app/log/',
    APP_UPDATE: '/app/update'
}