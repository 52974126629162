import StaticPageApi from '../Api/staticPage';

export const SET_ERROR = 'SET_STATIC_ERROR';
export const SET_TUNNING_DATA = 'SET_TUNNING_DATA';
export const SET_ROAD_HELP_DATA = 'SET_ROAD_HELP_DATA';
export const SET_LOADING = 'SET_STATIC_LOADING';

export function getTunning() {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await StaticPageApi.getTunning();
        console.log(data);
        if (result) {
            return dispatch({
                type: SET_TUNNING_DATA,
                tunning: data.content,
                tunningPhoneNumber: data.phoneNumber,
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function getRoadHelp() {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await StaticPageApi.getRoadHelp();
        if (result) {
            return dispatch({
                type: SET_ROAD_HELP_DATA,
                roadHelp: data.content,
                roadHelpPhoneNumber: data.phoneNumber,
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function saveTunning(value: string, phoneNumber: string) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await StaticPageApi.saveTunning(value, phoneNumber);
        if (result) {
            return dispatch({
                type: SET_TUNNING_DATA,
                tunning: data.content
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function saveRoadHelp(value: string, phoneNumber: string) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await StaticPageApi.saveRoadHelp(value, phoneNumber);
        if (result) {
            return dispatch({
                type: SET_ROAD_HELP_DATA,
                tunning: data.content
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}