import StaticPageApi from '../Api/staticPage';

export const SET_ERROR = 'SET_PRIVACY_ERROR';
export const SET_DATA = 'SET_PRIVACY_DATA';
export const SET_LOADING = 'SET_PRIVACY_LOADING';


export function getData() {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await StaticPageApi.getPrivacy();
        if (result) {
            return dispatch({
                type: SET_DATA,
                policy: data.policy,
                userAgreegment: data.userAgreegment
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function savePolicy(policy: string) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await StaticPageApi.savePolicy(policy);
        if (result) {
            return dispatch({
                type: SET_DATA,
                policy: data.policy,
                userAgreegment: data.userAgreegment
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function saveUserAgreegment(userAgreegment: string) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await StaticPageApi.saveUserAgreegment(userAgreegment);
        if (result) {
            return dispatch({
                type: SET_DATA,
                policy: data.policy,
                userAgreegment: data.userAgreegment
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}
