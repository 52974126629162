import React from 'react';
import { connect } from 'react-redux';
import { create, getList, remove } from './../Actions/newsSource';
import ListNewsSource from '../Components/NewsSource/List';
import Routes from './../Shared/routes';
import NewsSource from './../Entities/NewsSource';

interface IProps {
    isLoading: boolean;
    error: string;
    list: NewsSource[];
    getNewsSourceList: (page?: number) => void;
    remove: (nId: number) => void;
    history: any;
    currentPage: number;
    pages: number;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class NewsSourceListPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        if (!this.props.list.length) {
            await this.props.getNewsSourceList();
        }
        else {
            this.forceUpdate();
        }
    }

    onRemove = async (nId: number) => {
        await this.props.remove(nId);
        this.props.history.goBack();
    }

    onAddSource = () => {
        this.props.history.push(Routes.NEWS_SOURCE_CREATE);
    }

    onLoadMore = (page: number) => {
        this.props.getNewsSourceList(page);
    }

    render() {
        const { error, isLoading, list, currentPage, pages } = this.props;
        return (
            <ListNewsSource
                pages={pages}
                currentPage={currentPage}
                onLoadMore={this.onLoadMore}
                error={error}
                isLoading={isLoading}
                list={list}
                addSource={this.onAddSource}
                remove={this.onRemove}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        create: (title: string, link: string, status: number) => dispatch(create(title, link, status)),
        getNewsSourceList: (page?: number) => dispatch(getList(page)),
        remove: (nId: number) => dispatch(remove(nId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.newsSource.isLoading,
        list: state.newsSource.list,
        error: state.newsSource.error,
        currentPage: state.newsSource.currentPage,
        pages: state.newsSource.pages
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(NewsSourceListPage);
