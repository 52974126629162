import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Routes from './../Shared/routes';
import ManagerApi from './../Api/manager';

interface IProps {
    children: any;
    account: any;
}

interface IState {
    expanded: any;
}

class Container extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            expanded: null,
        };
    }
    async click(expanded: any) {
        if (this.state.expanded === expanded) expanded = null;
        this.setState({
            expanded,
        });
    }

    logout = async () => {
        await ManagerApi.logout();
        
    }

    onManagerCreate() {

    }

    render() {
        const { children } = this.props;
        return (<>
            <header className="app-header"><a className="app-header__logo" href="index.html">Avtodel</a>
                <a className="app-sidebar__toggle" href="#" data-toggle="sidebar" aria-label="Hide Sidebar"></a>
                <ul className="app-nav">
                    {/* <li className="app-search">
                        <input className="app-search__input" type="search" placeholder="Search" />
                        <button className="app-search__button"><i className="fa fa-search"></i></button>
                    </li>
                    <li className="dropdown"><a className="app-nav__item" href="#" data-toggle="dropdown" aria-label="Show notifications"><i className="fa fa-bell-o fa-lg"></i></a>
                        <ul className="app-notification dropdown-menu dropdown-menu-right">
                            <li className="app-notification__title">You have 4 new notifications.</li>
                            <div className="app-notification__content">
                                <li><a className="app-notification__item" href="javascript:;"><span className="app-notification__icon"><span className="fa-stack fa-lg"><i className="fa fa-circle fa-stack-2x text-primary"></i><i className="fa fa-envelope fa-stack-1x fa-inverse"></i></span></span>
                                    <div>
                                        <p className="app-notification__message">Lisa sent you a mail</p>
                                        <p className="app-notification__meta">2 min ago</p>
                                    </div></a></li>
                                <li><a className="app-notification__item" href="javascript:;"><span className="app-notification__icon"><span className="fa-stack fa-lg"><i className="fa fa-circle fa-stack-2x text-danger"></i><i className="fa fa-hdd-o fa-stack-1x fa-inverse"></i></span></span>
                                    <div>
                                        <p className="app-notification__message">Mail server not working</p>
                                        <p className="app-notification__meta">5 min ago</p>
                                    </div></a></li>
                                <li><a className="app-notification__item" href="javascript:;"><span className="app-notification__icon"><span className="fa-stack fa-lg"><i className="fa fa-circle fa-stack-2x text-success"></i><i className="fa fa-money fa-stack-1x fa-inverse"></i></span></span>
                                    <div>
                                        <p className="app-notification__message">Transaction complete</p>
                                        <p className="app-notification__meta">2 days ago</p>
                                    </div></a></li>
                                <div className="app-notification__content">
                                    <li><a className="app-notification__item" href="javascript:;"><span className="app-notification__icon"><span className="fa-stack fa-lg"><i className="fa fa-circle fa-stack-2x text-primary"></i><i className="fa fa-envelope fa-stack-1x fa-inverse"></i></span></span>
                                        <div>
                                            <p className="app-notification__message">Lisa sent you a mail</p>
                                            <p className="app-notification__meta">2 min ago</p>
                                        </div></a></li>
                                    <li><a className="app-notification__item" href="javascript:;"><span className="app-notification__icon"><span className="fa-stack fa-lg"><i className="fa fa-circle fa-stack-2x text-danger"></i><i className="fa fa-hdd-o fa-stack-1x fa-inverse"></i></span></span>
                                        <div>
                                            <p className="app-notification__message">Mail server not working</p>
                                            <p className="app-notification__meta">5 min ago</p>
                                        </div></a></li>
                                    <li><a className="app-notification__item" href="javascript:;"><span className="app-notification__icon"><span className="fa-stack fa-lg"><i className="fa fa-circle fa-stack-2x text-success"></i><i className="fa fa-money fa-stack-1x fa-inverse"></i></span></span>
                                        <div>
                                            <p className="app-notification__message">Transaction complete</p>
                                            <p className="app-notification__meta">2 days ago</p>
                                        </div></a></li>
                                </div>
                            </div>
                            <li className="app-notification__footer"><a href="#">See all notifications.</a></li>
                        </ul>
                    </li> */}
                    {/* <li className="dropdown"><a className="app-nav__item" onClick={this.logout} data-toggle="dropdown" aria-label="Open Profile Menu"><i className="fa fa-user fa-lg"></i></a>
                        <ul className="dropdown-menu settings-menu dropdown-menu-right">
                            <li><a className="dropdown-item" href="page-user.html"><i className="fa fa-cog fa-lg"></i> Settings</a></li>
                            <li><a className="dropdown-item" href="page-user.html"><i className="fa fa-user fa-lg"></i> Profile</a></li>
                           
                        </ul>
                    </li> */}
                    <li className="dropdown"><a className="app-nav__item" onClick={this.logout}  ><i className="fa fa-sign-out fa-lg"></i> Logout</a></li>
                </ul>
            </header>
            <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
            <aside className="app-sidebar">
                <div className="app-sidebar__user">
                    {/* <img className="app-sidebar__user-avatar" src="https://s3.amazonaws.com/uifaces/faces/twitter/jsa/48.jpg" alt="User Image" /> */}
                    <div>
                        <p className="app-sidebar__user-name">{this.props.account?.firstName} {this.props.account?.lastName}</p>
                        {/* <p className="app-sidebar__user-designation">Frontend Developer</p> */}
                    </div>
                </div>
                <ul className="app-menu">
                    {/* <li><a className="app-menu__item active" href="dashboard.html">
                        <i className="app-menu__icon fa fa-dashboard"></i><span className="app-menu__label">Dashboard</span></a></li> */}
                    <li className={this.state.expanded === 'manager' ? 'treeview is-expanded' : 'treeview'}><a onClick={() => this.click('manager')} className="app-menu__item" href="#" data-toggle="treeview">
                        <i className="app-menu__icon fa fa-th-list"></i>
                        <span className="app-menu__label">Менеджеры</span><i className="treeview-indicator fa fa-angle-right"></i></a>
                        <ul className="treeview-menu">
                            <li><Link
                                className="treeview-item"
                                to={Routes.MANAGER_CREATE}
                            ><i className="icon fa fa-circle-o"></i> Добавить</Link></li>
                            <li><Link
                                className="treeview-item"
                                to={Routes.MANAGER_LIST}
                            ><i className="icon fa fa-circle-o"></i> Список</Link></li>
                        </ul>
                    </li>
                    <li className={this.state.expanded === 'news' ? 'treeview is-expanded' : 'treeview'}>
                        <Link  to={Routes.CLIENT_LIST} className="app-menu__item" href="#" data-toggle="treeview">
                            <i className="app-menu__icon fa fa-th-list"></i>
                            <span className="app-menu__label">Клиенты</span>
                        </Link>
                    </li>
                    <li className={this.state.expanded === 'news' ? 'treeview is-expanded' : 'treeview'}>
                        <a onClick={() => this.click('news')} className="app-menu__item" href="#" data-toggle="treeview">
                            <i className="app-menu__icon fa fa-th-list"></i>
                            <span className="app-menu__label">Новости</span>
                            <i className="treeview-indicator fa fa-angle-right"></i>
                        </a>
                        <ul className="treeview-menu">
                            <li><Link
                                className="treeview-item"
                                to={Routes.NEWS_SOURCE_LIST}
                            ><i className="icon fa fa-circle-o"></i> Источники</Link></li>
                            <li><Link
                                className="treeview-item"
                                to={Routes.NEWS_CATEGORY_LIST}
                            ><i className="icon fa fa-circle-o"></i> Категории</Link></li>
                            <li><Link
                                className="treeview-item"
                                to={Routes.NEWS_LIST}
                            ><i className="icon fa fa-circle-o"></i> Список</Link></li>
                        </ul>
                    </li>
                    <li className={this.state.expanded === 'storeObjects' ? 'treeview is-expanded' : 'treeview'}>
                        <a onClick={() => this.click('storeObjects')} className="app-menu__item" href="#" data-toggle="treeview">
                            <i className="app-menu__icon fa fa-th-list"></i>
                            <span className="app-menu__label">Салоны</span>
                            <i className="treeview-indicator fa fa-angle-right"></i>
                        </a>
                        <ul className="treeview-menu">
                            {/* <li><Link
                                className="treeview-item"
                                to={Routes.STORE_OBJECT_CREATE}
                            ><i className="icon fa fa-circle-o"></i> Добавить</Link></li> */}
                            <li><Link
                                className="treeview-item"
                                to={Routes.STORE_OBJECT_LIST}
                            ><i className="icon fa fa-circle-o"></i> Список</Link></li>
                        </ul>
                    </li>
                    <li className={this.state.expanded === 'storeObjectsCategory' ? 'treeview is-expanded' : 'treeview'}>
                        <a onClick={() => this.click('storeObjectsCategory')} className="app-menu__item" href="#" data-toggle="treeview">
                            <i className="app-menu__icon fa fa-th-list"></i>
                            <span className="app-menu__label">Категории салона</span>
                            <i className="treeview-indicator fa fa-angle-right"></i>
                        </a>
                        <ul className="treeview-menu">
                            <li><Link
                                className="treeview-item"
                                to={Routes.STORE_OBJECT_CATEGORY_CREATE}
                            ><i className="icon fa fa-circle-o"></i> Добавить</Link></li>
                            <li><Link
                                className="treeview-item"
                                to={Routes.STORE_OBJECT_CATEGORY_LIST}
                            ><i className="icon fa fa-circle-o"></i> Список</Link></li>
                        </ul>
                    </li>
                    <li className={this.state.expanded === 'requests' ? 'treeview is-expanded' : 'treeview'}>
                        <a onClick={() => this.click('requests')} className="app-menu__item" href="#" data-toggle="treeview">
                            <i className="app-menu__icon fa fa-th-list"></i>
                            <span className="app-menu__label">Заявки</span>
                            <i className="treeview-indicator fa fa-angle-right"></i>
                        </a>
                        <ul className="treeview-menu">
                            <li><Link
                                className="treeview-item"
                                to={Routes.REQUEST_LIST}
                            ><i className="icon fa fa-circle-o"></i> Список</Link></li>
                        </ul>
                    </li>
                    <li className={this.state.expanded === 'privacy' ? 'treeview is-expanded' : 'treeview'}>
                        <Link  to={Routes.PRIVACY} className="app-menu__item" href="#" data-toggle="treeview">
                            <i className="app-menu__icon fa fa-th-list"></i>
                            <span className="app-menu__label">Правовая информация</span>
                        </Link>
                    </li>
                    <li className={this.state.expanded === 'static' ? 'treeview is-expanded' : 'treeview'}>
                        <a onClick={() => this.click('static')} className="app-menu__item" href="#" data-toggle="treeview">
                            <i className="app-menu__icon fa fa-th-list"></i>
                            <span className="app-menu__label">Статические страницы</span>
                            <i className="treeview-indicator fa fa-angle-right"></i>
                        </a>
                        <ul className="treeview-menu">
                            <li><Link
                                className="treeview-item"
                                to={Routes.EDIT_STATIC_TUNNING}
                            ><i className="icon fa fa-circle-o"></i> Тюннинг</Link></li>
                              <li><Link
                                className="treeview-item"
                                to={Routes.EDIT_STATIC_ROAD_HELP}
                            ><i className="icon fa fa-circle-o"></i> Помощь на дороге</Link></li>
                        </ul>
                    </li>
                    <li className={this.state.expanded === 'log' ? 'treeview is-expanded' : 'treeview'}>
                        <Link  to={Routes.APP_ERROR_LOG} className="app-menu__item" href="#" data-toggle="treeview">
                            <i className="app-menu__icon fa fa-th-list"></i>
                            <span className="app-menu__label">Лог ошибок приложения</span>
                        </Link>
                    </li>
                    <li className={this.state.expanded === 'log' ? 'treeview is-expanded' : 'treeview'}>
                        <Link  to={Routes.APP_UPDATE} className="app-menu__item" href="#" data-toggle="treeview">
                            <i className="app-menu__icon fa fa-th-list"></i>
                            <span className="app-menu__label">Обновление приложения</span>
                        </Link>
                    </li>
                  
                    {/* <li><a className="app-menu__item" href="charts.html"><i className="app-menu__icon fa fa-pie-chart"></i><span className="app-menu__label">Charts</span></a></li>
                    <li className="treeview"><a className="app-menu__item" href="#" data-toggle="treeview"><i className="app-menu__icon fa fa-edit"></i><span className="app-menu__label">Forms</span><i className="treeview-indicator fa fa-angle-right"></i></a>
                        <ul className="treeview-menu">
                            <li><a className="treeview-item" href="form-components.html"><i className="icon fa fa-circle-o"></i> Form Components</a></li>
                            <li><a className="treeview-item" href="form-custom.html"><i className="icon fa fa-circle-o"></i> Custom Components</a></li>
                            <li><a className="treeview-item" href="form-samples.html"><i className="icon fa fa-circle-o"></i> Form Samples</a></li>
                            <li><a className="treeview-item" href="form-notifications.html"><i className="icon fa fa-circle-o"></i> Form Notifications</a></li>
                        </ul>
                    </li>
                    <li className="treeview"><a className="app-menu__item" href="#" data-toggle="treeview"><i className="app-menu__icon fa fa-th-list"></i><span className="app-menu__label">Tables</span><i className="treeview-indicator fa fa-angle-right"></i></a>
                        <ul className="treeview-menu">
                            <li><a className="treeview-item" href="table-basic.html"><i className="icon fa fa-circle-o"></i> Basic Tables</a></li>
                            <li><a className="treeview-item" href="table-data-table.html"><i className="icon fa fa-circle-o"></i> Data Tables</a></li>
                        </ul>
                    </li>
                    <li className="treeview"><a className="app-menu__item" href="#" data-toggle="treeview"><i className="app-menu__icon fa fa-file-text"></i><span className="app-menu__label">Pages</span><i className="treeview-indicator fa fa-angle-right"></i></a>
                        <ul className="treeview-menu">
                            <li><a className="treeview-item" href="blank-page.html"><i className="icon fa fa-circle-o"></i> Blank Page</a></li>
                            <li><a className="treeview-item" href="page-login.html"><i className="icon fa fa-circle-o"></i> Login Page</a></li>
                            <li><a className="treeview-item" href="page-lockscreen.html"><i className="icon fa fa-circle-o"></i> Lockscreen Page</a></li>
                            <li><a className="treeview-item" href="page-user.html"><i className="icon fa fa-circle-o"></i> User Page</a></li>
                            <li><a className="treeview-item" href="page-invoice.html"><i className="icon fa fa-circle-o"></i> Invoice Page</a></li>
                            <li><a className="treeview-item" href="page-calendar.html"><i className="icon fa fa-circle-o"></i> Calendar Page</a></li>
                            <li><a className="treeview-item" href="page-mailbox.html"><i className="icon fa fa-circle-o"></i> Mailbox</a></li>
                            <li><a className="treeview-item" href="page-error.html"><i className="icon fa fa-circle-o"></i> Error Page</a></li>
                        </ul>
                    </li>
                    <li><a className="app-menu__item" href="docs.html"><i className="app-menu__icon fa fa-file-code-o"></i><span className="app-menu__label">Docs</span></a></li> */}
                </ul>
            </aside>
            <main className="app-content">
                {children}
            </main>
        </>
        );
    }
}


function bindAction(dispatch: any) {
    return {
    };
}

const mapStateToProps = (state: any) => {
    return {
        account: state.manager.current
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(Container);
