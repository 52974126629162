import React from 'react';
import { connect } from 'react-redux';
import { getList } from '../Actions/notification';
import NotificationList from '../Components/Notification/List';
import Notification from '../Entities/Notification';

interface IProps {
    isLoading: boolean;
    error: string;
    list: Notification[];
    getList: (clientId: number, page?: number) => void;
    currentPage: number;
    pages: number;
    history: any;
    match: any;
}

class NotificationListPage extends React.Component<IProps> {
    clientId: number;

    constructor(props: IProps) {
        super(props);
        this.clientId = props.match.params.clientId;
    }

    async componentDidMount() {
        this.props.getList(this.clientId);
    }

    onLoadMore = (page: number) => {
        this.props.getList(this.clientId, page);
    }

    render() {
        const { error, isLoading, list, currentPage, pages } = this.props;
        return (
            <NotificationList
                pages={pages}
                currentPage={currentPage}
                onLoadMore={this.onLoadMore}
                error={error}
                isLoading={isLoading}
                list={list}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getList: (clientId: number, page?: number) => dispatch(getList(clientId, page)),
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.notification.isLoading,
        list: state.notification.list,
        error: state.notification.error,
        currentPage: state.notification.currentPage,
        pages: state.notification.pages
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(NotificationListPage);
