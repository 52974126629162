import Api from '.';
import { IRequestResponse } from './index';
import { IStoreObjectCategoryEdit } from '../Shared/interfaces';

class OperationCategory extends Api {
    ENDPOINT = 'cars-store-object/category';

    create(data: IStoreObjectCategoryEdit) {
        return this.post(this.ENDPOINT, data);
    }

    edit(id: number, data: IStoreObjectCategoryEdit) {
        return this.put(`${this.ENDPOINT}/${id}`, data);
    }

    remove(id: number) {
        return this.delete(`${this.ENDPOINT}/${id}`);
    }

    getOne(id: number) {
        return this.get(`${this.ENDPOINT}/${id}`);
    }

    getList(page: number | null = null): Promise<IRequestResponse> {
        return this.get(`${this.ENDPOINT}`, {
            page
        });
    }
}


const oc = new OperationCategory();

export default oc;

