import { SET_ERROR, SET_LIST, SET_LOADING } from '../Actions/log';
import { Log } from '../Entities/Log';

interface IState {
    list: Log[],
    currentPage: number,
    pages: number;
    error: string | null;
    isLoading: boolean;
};

const defaultState = {
    list: [],
    error: null,
    isLoading: false,
    pages: 0,
    currentPage: 0
};

export default function log(state: IState = defaultState, action: any) {
    switch (action.type) {
        case SET_LIST:
            return {
                ...state,
                list: action.data,
                pages: action.pages,
                currentPage: action.currentPage,
                isLoading: false
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }

        default:
            return state;
    }
}
