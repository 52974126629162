import { BaseEntity } from "./BaseEntity";

export default class NewsCategory extends BaseEntity {
    private title: string;
    private color: string;

    constructor(id: number, title: string, color: string = '') {
        super(id);
        this.title = title;
        this.color = color;
    }

    getTitle(): string {
        return this.title;
    }

    getColor(): string {
        return this.color;
    }
}