import { BaseEntity } from "./BaseEntity";
import NewsSource from "./NewsSource";
import NewsCategory from "./NewsCategory";
import CarsStoreObject from "./CarsStoreObject";

export default class News extends BaseEntity {
    private title: string;
    private body: string;
    private imageSrc: string;
    private endAt: any;
    private category: NewsCategory;
    private storeObject: CarsStoreObject | null;
    private source: NewsSource;
    private isVisible: boolean;

    constructor(id: number, title: string, body: string, imageSrc: string,
        category: NewsCategory, source: NewsSource, endAt: any, isVisible: boolean, storeObject: CarsStoreObject | null = null) {
        super(id);
        this.title = title;
        this.body = body;
        this.category = category;
        this.source = source;
        this.isVisible = isVisible;
        this.imageSrc = imageSrc;
        this.storeObject = storeObject;
    }

    getTitle(): string {
        return this.title;
    }

    getBody(): string {
        return this.body;
    }

    getCategory(): NewsCategory {
        return this.category;
    }

    getSource(): NewsSource {
        return this.source;
    }

    getVisibility(): boolean {
        return this.isVisible;
    }

    getImage(): string {
        return this.imageSrc;
    }

    getEndAt(): string {
        return this.endAt;
    }

    getCarsStoreObject(): CarsStoreObject | null {
        return this.storeObject;
    }
}