import React from 'react';
import { connect } from 'react-redux';
import { edit, getList } from '../Actions/storeObject';
import {  getList as getCategoryList } from '../Actions/store-object-category';
import Routes from '../Shared/routes';
import EditStoreObject from '../Components/StoreObject/Item';
import { IStoreObjectEdit } from '../Shared/interfaces';
import CarsStoreObject from '../Entities/CarsStoreObject';
import Loading from '../Components/Loading';
import { StoreObjectCategory } from '../Entities/StoreObjectCategory';


interface IProps {
    isLoading: boolean;
    error: number;
    getList: () => void;
    getCategoryList: () => void;
    categoryList: StoreObjectCategory[];
    edit: (id: number, data: IStoreObjectEdit) => void;
    list: CarsStoreObject[];
    history: any;
    navigation: any;
    match: any;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class EditStoreObjectPage extends React.Component<IProps, IState> {
    storeObject: CarsStoreObject | undefined;

    async componentDidMount() {
        const id = this.props.match.params.storeObjectId;
        await this.props.getCategoryList();
        if (!this.props.list.length) {
            await this.props.getList();
        }
        this.storeObject = this.props.list.find((s: CarsStoreObject) => s.getId() == id);
        if (!this.storeObject) {
            this.props.history.goBack();
        }
        else {
            this.forceUpdate();
        }
    }

    onSubmit = async (data: IStoreObjectEdit) => {
        if (this.storeObject?.getId()) {
            await this.props.edit(this.storeObject?.getId(), data);
            if (!this.props.error) {
                this.props.history.push(Routes.STORE_OBJECT_LIST)
            }
        }
    }

    render() {
        if (!this.storeObject) {
            return <Loading />;
        }
        return (
            <EditStoreObject
                item={this.storeObject}
                error={this.props.error}
                categoryList={this.props.categoryList}
                isLoading={this.props.isLoading}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        edit: (id: number, data: IStoreObjectEdit) => dispatch(edit(id, data)),
        getList: () => dispatch(getList()),
        getCategoryList: () => dispatch(getCategoryList()),
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.storeObject.isLoading,
        error: state.storeObject.error,
        list: state.storeObject.list,
        categoryList: state.storeObjectCategory.list,
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EditStoreObjectPage);
