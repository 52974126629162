import NotificationAPI from '../Api/notification';
import { mapNotifications } from '../Shared/mappers';

export const SET_ERROR = 'SET_NOTIFICATION_ERROR';
export const SET_LIST = 'SET_NOTIFICATION_LIST';
export const SET_LOADING = 'SET_NOTIFICATION_LOADING';


export function getList(clientId: number, page: number = 1) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await NotificationAPI.getList(clientId, page);
        if (result) {
            return dispatch({
                type: SET_LIST,
                pages: data.pages,
                currentPage: data.currentPage,
                list: mapNotifications(data.list)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}
