import React from 'react';
import { connect } from 'react-redux';
import { getList } from './../Actions/request';
import ListRequest from '../Components/Request/List';
import Request from './../Entities/Request';

interface IProps {
    isLoading: boolean;
    error: string;
    list: Request[];
    getRequests: (page?: number) => void;
    remove: (nId: number) => void;
    history: any;
    currentPage: number;
    pages: number;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class RequestList extends React.Component<IProps, IState> {

    componentDidMount() {
        if (!this.props.list.length) {
            this.props.getRequests();
        }
    }

    onLoadMore = (page: number) => {
        this.props.getRequests(page);
    }

    render() {
        const { error, isLoading, list, currentPage, pages } = this.props;
        return (
            <ListRequest
                pages={pages}
                currentPage={currentPage}
                onLoadMore={this.onLoadMore}
                error={error}
                isLoading={isLoading}
                list={list}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getRequests: (page?: number) => dispatch(getList(page)),
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.request.isLoading,
        list: state.request.list,
        error: state.request.error,
        currentPage: state.request.currentPage,
        pages: state.request.pages
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(RequestList);
