import moment from 'moment';
import { BaseEntity } from "./BaseEntity";
import { LOG_TYPE, LOG_TYPE_TITLE } from "../Shared/const";

export class Log extends BaseEntity {

    constructor(id: number, public clientId: number, private type: LOG_TYPE,
        public message: string, private createdAt: Date) {
        super(id);
    }

    getType(): string {
        return LOG_TYPE_TITLE[this.type];
    }

    getCreatedAt(): string {
        return moment(this.createdAt).format('DD MMMM YYYY, HH:mm');
    }
}