import React from 'react';
import { connect } from 'react-redux';
import { getList, remove } from './../Actions/newsCategory';
import ListNewsSource from '../Components/NewsCategory/List';
import Routes from './../Shared/routes';
import NewsCategory from './../Entities/NewsCategory';

interface IProps {
    isLoading: boolean;
    error: string;
    list: NewsCategory[];
    getNewsCategoryList: (page?: number) => void;
    remove: (nId: number) => void;
    history: any;
    currentPage: number;
    pages: number;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class NewsCategoryListPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        if (!this.props.list.length) {
            await this.props.getNewsCategoryList();
        }
        else {
            this.forceUpdate();
        }
    }

    onRemove = async (nId: number) => {
        await this.props.remove(nId);
        this.props.history.goBack();
    }

    onAddSource = () => {
        this.props.history.push(Routes.NEWS_CATEGORY_CREATE);
    }

    onLoadMore = (page: number) => {
        this.props.getNewsCategoryList(page);
    }

    render() {
        const { error, isLoading, list, currentPage, pages } = this.props;
        return (
            <ListNewsSource
                pages={pages}
                currentPage={currentPage}
                onLoadMore={this.onLoadMore}
                error={error}
                isLoading={isLoading}
                list={list}
                addSource={this.onAddSource}
                remove={this.onRemove}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getNewsCategoryList: (page?: number) => dispatch(getList(page)),
        remove: (nId: number) => dispatch(remove(nId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.newsCategory.isLoading,
        list: state.newsCategory.list,
        error: state.newsCategory.error,
        currentPage: state.newsCategory.currentPage,
        pages: state.newsCategory.pages
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(NewsCategoryListPage);
