import { SET_DATA, SET_ERROR, SET_LOADING } from '../Actions/updateApp';
import { Log } from '../Entities/Log';
import { IUpdateApk } from '../Shared/interfaces';

interface IState {
    data: IUpdateApk | null;
    error: string | null;
    isLoading: boolean;
};

const defaultState = {
    data: null,
    error: null,
    isLoading: false,
};

export default function log(state: IState = defaultState, action: any) {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                data: action.data,
                error: action.pages,
                isLoading: false
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }

        default:
            return state;
    }
}
