import React from 'react';
import { connect } from 'react-redux';
import { create } from './../Actions/newsCategory';
import Routes from './../Shared/routes';
import AddNewsSource from '../Components/NewsCategory/Item';
import { FILL_PARAMS_ERROR } from '../Shared/errors';


interface IProps {
    isLoading: boolean;
    error: number | null;
    create: (title: string, color: string) => void;
    history: any;
}

interface IState {
    error: number | null;
}

class AddNewsCategoryPage extends React.Component<IProps, IState> {

    state = {
        error: null
    }

    onSubmit = async (title: string, color: string) => {
        if (!title || !color) {
            this.setState({
                error: FILL_PARAMS_ERROR
            })
        }
        else {
            await this.props.create(title, color);
            if (!this.props.error) {
                this.props.history.push(Routes.NEWS_CATEGORY_LIST)
            }
        }
    }

    render() {
        return (
            <AddNewsSource
                error={this.props.error || this.state.error}
                isLoading={this.props.isLoading}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        create: (title: string, color: string) => dispatch(create(title, color))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.newsSource.isLoading,
        error: state.newsSource.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(AddNewsCategoryPage);
