import { API_URL } from '../config';
import { setToken, getToken, removeToken } from './../localStorage';
import { SERVER_ERROR } from './../Shared/errors';

const HTTP_STATUS = {
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    SERVER_ERROR: 500
}

export interface IRequest {
    method: string;
    headers: any;
    body?: string;
}

export interface IFetch {
    path: string;
    request: IRequest;
}

export interface IRequestResponse {
    result: boolean;
    data?: any;
    status?: number;
    error: number;
}

class Api {
    static token: string = '';
    private failedTokenValidationListeners: any = [];
    private logoutListeners: any = [];
    private failedNetworkListeners: any = [];

    post(path: string, body: any, headers: any = {}): Promise<IRequestResponse> {
        let request: IRequest = {
            headers,
            method: 'POST',
            body: JSON.stringify(body)
        };
        request.headers['Content-type'] = 'application/json';
        return this.fetch({ path, request })
    }

    get(path: string, params?: any, headers: any = {}): Promise<IRequestResponse> {
        let request: IRequest = {
            headers,
            method: 'GET'
        };
        path = path + this.formatGetParams(params);
        return this.fetch({ path, request })
    }

    put(path: string, body: any, headers: any = {}): Promise<IRequestResponse> {
        let request: IRequest = {
            headers,
            method: 'PUT',
            body: JSON.stringify(body)
        };
        request.headers['Content-type'] = 'application/json';
        return this.fetch({ path, request })
    }

    patch(path: string, body: any, headers: any = {}): Promise<IRequestResponse> {
        let request: IRequest = {
            headers,
            method: 'PATCH',
            body
        };
        request.headers['Content-type'] = 'application/json';
        return this.fetch({ path, request })
    }

    patchMultipart(path: string, body: any, headers: any = {}): Promise<IRequestResponse> {
        let request: IRequest = {
            headers,
            method: 'PATCH',
            body
        };

        return this.fetch({ path, request })
    }

    delete(path: string, headers: any = {}): Promise<IRequestResponse> {
        let request: IRequest = {
            headers,
            method: 'DELETE'
        };
        return this.fetch({ path, request })
    }

    fetch({ path, request }: IFetch): Promise<IRequestResponse> {
        if (!Api.token) {
            this.getToken();
        }
        if (Api.token) request.headers.Authorization = `Bearer ${Api.token}`;
        return fetch(API_URL + path, request)
            .then(async response => {
                const result = await response.json();
                let isSuccess = false;
                if (this.isSuccessStatus(response.status)) {
                    isSuccess = true;
                }
                else if (response.status === HTTP_STATUS.UNAUTHORIZED) {
                    this.emitOnFailedTokenValidation();
                }
                else if (response.status === HTTP_STATUS.SERVER_ERROR) {
                    this.emitOnFailedNetwork();
                }
                return {
                    result: isSuccess,
                    error: result.error,
                    data: result,
                    status: response.status
                }
            })
            .catch(({ message }) => {
                this.emitOnFailedNetwork();
                return {
                    result: false,
                    error: SERVER_ERROR
                }
            });
    }

    getToken(): string {
        Api.token = getToken();
        return Api.token;
    }

    removeToken(): void {
        Api.token = '';
        removeToken();
    }

    saveToken(token: string): void {
        Api.token = token;
        setToken(token);
    }

    logout(){
        this.removeToken();
        this.logoutListeners.forEach((listener: any) => {
                listener();
        });
    }

    formatGetParams(params: any = {}): string {
        if (Object.keys(params).length === 0) {
            return '';
        }
        let result = '?';
        for (const param in params) {
            if (params[param]) {
                result += `${param}=${params[param]}&`;
            }
        }
        return result;
    }


    isSuccessStatus(status: number): boolean {
        return status === 200 || status === 201;
    }

    emitOnFailedTokenValidation(): void {
        for (const listener of this.failedTokenValidationListeners) {
            listener();
        }
    }

    emitOnFailedNetwork(): void {
        for (const listener of this.failedNetworkListeners) {
            listener();
        }
    }

    addLogoutListener(listener: any): void {
        this.logoutListeners.push(listener);
    }

    addFailedNetworkListener(listener: any): void {
        this.failedNetworkListeners.push(listener);
    }

    removeAllFailedNetworkListeners(): void {
        this.failedNetworkListeners = [];
    }

    removeAllLogoutListeners(): void {
        this.logoutListeners = [];
    }

    addFailedTokenValidationListener(listener: any): void {
        this.failedTokenValidationListeners.push(listener);
    }

    removeAllFailedTokenValidationListeners(): void {
        this.failedTokenValidationListeners = [];
    }
}


export default Api;