import React from 'react';
import { connect } from 'react-redux';
import { getCurrent } from './../Actions/managers';
import Loading from './../Components/Loading';
import Main from './Main';

interface IProps {
    getCurrent: () => void
}

interface IState {
    isLoading: boolean;
}

class Launch extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    async componentDidMount() {
        await this.props.getCurrent();
        this.setState({
            isLoading: false
        })
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        return <Main />;

    }
}

function mapStateToProps() {
    return {

    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        getCurrent: () => dispatch(getCurrent()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Launch);
