import React from 'react';
import { connect } from 'react-redux';
import { edit, getList } from './../Actions/newsSource';
import Routes from './../Shared/routes';
import EditNewsSource from '../Components/NewsSource/Item';
import NewsSource from './../Entities/NewsSource';
import { FILL_PARAMS_ERROR } from '../Shared/errors';

interface IProps {
    isLoading: boolean;
    error: number;
    list: NewsSource[];
    edit: (id: number, title: string, link: string, status: number) => void;
    getNewsSourceList: () => void;
    match: any;
    history: any;
}

interface IState {
    error: number | null;
}

class EditNewsSourcePage extends React.Component<IProps, IState> {
    newsSource: NewsSource | undefined;

    state = {
        error: null
    }

    async componentDidMount() {
        if (!this.props.list.length) {
            await this.props.getNewsSourceList();
        }
        const id = this.props.match.params.newsSourceId;
        this.newsSource = this.props.list.find((n: NewsSource) => n.getId() == id);
        if (!this.newsSource) {
            this.props.history.push(Routes.NEWS_SOURCE_LIST);
        }
        else {
            this.forceUpdate();
        }
    }

    onSubmit = async (title: string, link: string, status: number) => {
        if (this.newsSource) {
            if (!(title && link && status)) {
                this.setState({
                    error: FILL_PARAMS_ERROR
                })
            }
            else {
                await this.props.edit(this.newsSource.getId(), title, link, status);
                if (!this.props.error) {
                    this.props.history.push(Routes.NEWS_SOURCE_LIST);
                }
            }
        }
    }

    render() {
        return (
            <EditNewsSource
                error={this.props.error || this.state.error}
                isLoading={this.props.isLoading}
                newsSource={this.newsSource}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        edit: (id: number, title: string, link: string, status: number) => dispatch(edit(id, title, link, status)),
        getNewsSourceList: () => dispatch(getList())
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.newsSource.isLoading,
        list: state.newsSource.list,
        error: state.newsSource.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EditNewsSourcePage);
