import React from 'react';
import Loading from '../Loading';
import CarsStoreObject from '../../Entities/CarsStoreObject';
import { IStoreObjectEdit } from '../../Shared/interfaces';
import { StoreObjectCategory } from '../../Entities/StoreObjectCategory';

interface IState {
    isHaveRepairStation: boolean;
    isHaveTradeIn: boolean;
    isHaveTestDrive: boolean;
    isHaveNewAuto: boolean;
    isHaveCommission: boolean;
    chatChannelId: number | null;
    categoryId: number | undefined;
    sortOrder: number | undefined;
    isVisible: boolean;
    isVisibleIOS: boolean;
}

interface IProps {
    item: CarsStoreObject;
    error: number;
    categoryList: StoreObjectCategory[];
    onSubmit: (data: IStoreObjectEdit) => void;
    isLoading: boolean;
}

export default class ItemStoreObject extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            chatChannelId: props.item.chatChannelId,
            isHaveRepairStation: !!props.item.isHaveRepairStation,
            isHaveTradeIn: !!props.item.isHaveTradeIn,
            isHaveTestDrive: !!props.item.isHaveTestDrive,
            isHaveNewAuto: !!props.item.isHaveNewAuto,
            isHaveCommission: !!props.item.isHaveCommission,
            isVisible: !!props.item.isVisible,
            isVisibleIOS: !!props.item.isVisibleIOS,
            sortOrder: props.item.sortOrder,
            categoryId: props.item.categoryId,
        }
    }

    handleChangeChannelId = (event: any) => {
        const target = event.target;
        this.setState({
            chatChannelId: target.value
        })
    }

    handleChangeSortOrder = (event: any) => {
      const target = event.target;
      this.setState({
          sortOrder: target.value
      })
  }

    handleChangeHaveRepairStation = (event: any) => {
        this.setState({
            isHaveRepairStation: !this.state.isHaveRepairStation
        })
    }

    handleChangeHaveTradeIn = (event: any) => {
        this.setState({
            isHaveTradeIn: !this.state.isHaveTradeIn
        })
    }

    handleChangeHaveTestDrive = (event: any) => {
        this.setState({
            isHaveTestDrive: !this.state.isHaveTestDrive
        })
    }

    handleChangeHaveNewAuto = (event: any) => {
        this.setState({
            isHaveNewAuto: !this.state.isHaveNewAuto
        })
    }

    handleChangeCommission = (event: any) => {
        this.setState({
            isHaveCommission: !this.state.isHaveCommission
        })
    }

    handleVisibilityChange = (event: any) => {
        this.setState({
            isVisible: !this.state.isVisible,
        });
    }

    handleChangeCategory = (event: any) => {
      const target = event.target;
      this.setState({
          categoryId: Number(target.value)
      });
    }

    handleVisibilityIOSChange = (event: any) => {
        const target = event.target;
        this.setState({
            isVisibleIOS: !!Number(target.value)
        });
    }

    onSubmit = () => {
        this.props.onSubmit({
            chatChannelId: Number(this.state.chatChannelId),
            isHaveNewAuto: this.state.isHaveNewAuto,
            isHaveRepairStation: this.state.isHaveRepairStation,
            isHaveTradeIn: this.state.isHaveTradeIn,
            isHaveTestDrive: this.state.isHaveTestDrive,
            isHaveCommission: this.state.isHaveCommission,
            isVisible: this.state.isVisible,
            isVisibleIOS: this.state.isVisibleIOS,
            sortOrder: Number(this.state.sortOrder),
            categoryId: this.state.categoryId ? Number(this.state.categoryId) : undefined,
        });
    }

    render() {
        const { isLoading, item, error } = this.props;
        if (isLoading || !item) {
            return <Loading />;
        }
        else {
            return (<div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            {item.imageSrc ?
                                <div className="app-sidebar__user">
                                    <img className="app-sidebar__user-avatar" src={String(item.imageSrc)} alt="User Image" />
                                </div> : null}
                            <div className="col-12 table-responsive">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Id</td>
                                            <td>{item.getId()}</td>
                                        </tr>
                                        <tr>
                                            <td>Название</td>
                                            <td>{item.title}</td>
                                        </tr>
                                        <tr>
                                            <td>Сайт</td>
                                            <td>{item.link}</td>
                                        </tr>
                                        <tr>
                                            <td>Номер телефона</td>
                                            <td>{item.phone}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <p>{error}</p>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Id канала в чате</label>
                                        <input
                                            className="form-control"
                                            name="chatChannelId"
                                            value={this.state.chatChannelId ? this.state.chatChannelId : ''}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeChannelId}
                                            placeholder="Введите id канала" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Позиция в сортировке</label>
                                        <input
                                            className="form-control"
                                            name="sortOrder"
                                            value={this.state.sortOrder ? this.state.sortOrder : ''}
                                            id="exampleInputEmail1"
                                            type="number"
                                            onChange={this.handleChangeSortOrder}
                                            placeholder="Введите позицию в сортировке" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleSelect2">Категория</label>
                                        <select
                                            onChange={this.handleChangeCategory}
                                            className="form-control" id="exampleSelect2">
                                              {this.props.categoryList.map((category)=> 
                                                <option
                                                  selected={this.state.categoryId===category.getId()}
                                                  value={category.getId()}>
                                                {category.name}
                                                </option>
                                              )}
                                              <option
                                                selected={!this.state.categoryId}
                                                value={undefined}>
                                                Не выбрано
                                              </option> 
                                        </select>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                checked={this.state.isHaveRepairStation}
                                                onChange={this.handleChangeHaveRepairStation}
                                                className="form-check-input"
                                                type="checkbox" />СТО
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                checked={this.state.isHaveNewAuto}
                                                onChange={this.handleChangeHaveNewAuto}
                                                className="form-check-input"
                                                type="checkbox" />Продажа авто
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                onChange={this.handleChangeHaveTradeIn}
                                                checked={this.state.isHaveTradeIn}
                                                className="form-check-input"
                                                type="checkbox" />Trade in
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                onChange={this.handleChangeHaveTestDrive}
                                                checked={this.state.isHaveTestDrive}
                                                className="form-check-input"
                                                type="checkbox" />Тест драйв
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                onChange={this.handleChangeCommission}
                                                checked={this.state.isHaveCommission}
                                                className="form-check-input"
                                                type="checkbox" />Выкуп, комиссия
                                        </label>
                                    </div>
                                    <br />
                                    <div className="form-group">
                                        <label htmlFor="exampleSelect2">Видимость</label>
                                        <select
                                            onChange={this.handleVisibilityChange}
                                            className="form-control" id="exampleSelect2" >
                                            <option
                                                selected={this.state.isVisible}
                                                value={1}>
                                                Активно
                                            </option>
                                            <option
                                                selected={!this.state.isVisible}
                                                value={0}>
                                                Скрыто
                                            </option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleSelect2">Видимость для IOS</label>
                                        <select
                                            onChange={this.handleVisibilityIOSChange}
                                            className="form-control" id="exampleSelect2" >
                                            <option
                                                selected={this.state.isVisibleIOS}
                                                value={1}>
                                                Активно
                                            </option>
                                            <option
                                                selected={!this.state.isVisibleIOS}
                                                value={0}>
                                                Скрыто
                                            </option>
                                        </select>
                                    </div>
                                </form>
                                <div className="tile-footer">
                                    <button
                                        onClick={this.onSubmit}
                                        className="btn btn-primary"
                                        type="submit">Сохранить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}