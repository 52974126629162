import React from 'react';
import { connect } from 'react-redux';
import { getRoadHelp, saveRoadHelp } from './../Actions/staticPage';
import RoadHelp from '../Components/StaticRoadHelp';

interface IProps {
	isLoading: boolean;
	error: string;
	get: () => void;
	save: (text: string, phoneNumber: string) => void;
	value: string;
	phoneNumber: string;
}

class StaticRoadHelp extends React.Component<IProps> {

	componentDidMount() {
		this.props.get();
	}

	onSubmit = (data: string, phoneNumber: string) => {
		this.props.save(data, phoneNumber);
	}

	render() {
		const { isLoading, value, phoneNumber } = this.props;
		return (
			<RoadHelp
				isLoading={isLoading}
				onSubmit={this.onSubmit}
				phoneNumber={phoneNumber}
				value={value} />
		);
	}
}

function bindAction(dispatch: any) {
	return {
		get: () => dispatch(getRoadHelp()),
		save: (data: string, phoneNumber: string) => dispatch(saveRoadHelp(data, phoneNumber)),
	};
}

const mapStateToProps = (state: any) => {
	return {
		isLoading: state.staticPage.isLoading,
		value: state.staticPage.roadHelp,
		phoneNumber: state.staticPage.roadHelpPhoneNumber,
		error: state.staticPage.error
	}
}

export default connect(
	mapStateToProps,
	bindAction
)(StaticRoadHelp);
