import React from 'react';
import { connect } from 'react-redux';
import { edit, getList } from '../Actions/managers';
import EditManagerView from '../Components/Manager/Item';
import Manager from '../Entities/Manager';
import { IManagerEdit } from '../Shared/interfaces';
import Routes from './../Shared/routes';
import { isEmailValid } from '../Shared/utils';
import { EMAIL_IS_INVALID } from '../Shared/errors';


interface IProps {
    isLoading: boolean;
    error: number;
    managerList: Manager[];
    getList: () => void;
    edit: (id: number, data: IManagerEdit) => void;
    history: any;
    match: any;
}

interface IState {
    error: number | null;
}

class EditManagerPage extends React.Component<IProps, IState> {
    manager: Manager | undefined;

    state = {
        error: null
    }

    async componentDidMount() {
        if (!this.props.managerList.length) {
            await this.props.getList();
        }
        const id = this.props.match.params.managerId;
        this.manager = this.props.managerList.find((n: Manager) => n.getId() == id);
        if (!this.manager) {
            this.props.history.push(Routes.MANAGER_LIST);
        }
        else {
            this.forceUpdate();
        }
    }

    onSubmit = async (data: IManagerEdit) => {
        if (this.manager) {
            if (!isEmailValid(data.email)) {
                this.setState({
                    error: EMAIL_IS_INVALID
                });
            }
            else {
                await this.props.edit(this.manager?.getId(), data);
                if (!this.props.error) {
                    this.props.history.push(Routes.MANAGER_LIST)
                }
            }
        }
    }

    render() {
        return (
            <EditManagerView
                manager={this.manager}
                error={this.props.error && this.state.error}
                isLoading={this.props.isLoading}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        edit: (id: number, data: IManagerEdit) => dispatch(edit(id, data)),
        getList: () => dispatch(getList())
    };
}

const mapStateToProps = (state: any) => {
    return {
        managerList: state.manager.list,
        isLoading: state.manager.isLoading,
        error: state.manager.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(EditManagerPage);
