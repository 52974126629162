import { SET_DATA, SET_LOADING, SET_ERROR } from '../Actions/privacy';

interface IState {
	policy: string;
	userAgreegment: string;
	error: number | null;
	isLoading: boolean;
};

const defaultState = {
	error: null,
	policy: '',
	userAgreegment: '',
	isLoading: false
};

export default function privacy(state: IState = defaultState, action: any) {
	switch (action.type) {
		case SET_DATA:
			return {
				...state,
				userAgreegment: action.userAgreegment,
				policy: action.policy,
				isLoading: false
			};
		case SET_LOADING:
			return {
				...state,
				isLoading: action.isLoading
			}
		case SET_ERROR: {
			return {
				...state,
				isLoading: false,
				error: action.error
			}
		}
		default:
			return state;
	}
}
