import React from 'react';
import { connect } from 'react-redux';
import { getTunning, saveTunning } from '../Actions/staticPage';
import Tunning from '../Components/StaticTuning';

interface IProps {
	isLoading: boolean;
	error: string;
	get: () => void;
	save: (text: string, phoneNumber: string) => void;
	value: string;
	phoneNumber: string;
}

class StaticTunning extends React.Component<IProps> {

	componentDidMount() {
		this.props.get();
	}

	onSubmit = (data: string, phoneNumber: string) => {
		this.props.save(data, phoneNumber);
	}

	render() {
		const { isLoading, value, phoneNumber } = this.props;
		return (
			<Tunning
				isLoading={isLoading}
				phoneNumber={phoneNumber}
				onSubmit={this.onSubmit}
				value={value} />
		);
	}
}

function bindAction(dispatch: any) {
	return {
		get: () => dispatch(getTunning()),
		save: (data: string, phoneNumber: string) => dispatch(saveTunning(data, phoneNumber)),
	};
}

const mapStateToProps = (state: any) => {
	return {
		isLoading: state.staticPage.isLoading,
		value: state.staticPage.tunning,
		phoneNumber: state.staticPage.tunningPhoneNumber,
		error: state.staticPage.error
	}
}

export default connect(
	mapStateToProps,
	bindAction
)(StaticTunning);
