import NewsCategoryApi from './../Api/newsCategory';
import { mapNewsCategories } from '../Shared/mappers';

export const SET_ERROR = 'SET_CATEGORY_ERROR';
export const SET_LIST = 'SET_CATEGORY_LIST';
export const SET_LOADING = 'SET_CATEGORY_LOADING';

export function create(title: string, color: string) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await NewsCategoryApi.create(title, color);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())
    }
}

export function edit(id: number, title: string, color: string) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await NewsCategoryApi.edit(id, title, color);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())

    }
}

export function getList(page: number = 1) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await NewsCategoryApi.getList(page);
        if (result) {
            return dispatch({
                type: SET_LIST,
                data: mapNewsCategories(data)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function remove(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await NewsCategoryApi.remove(id);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList());
    }
}