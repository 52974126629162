import Api from '.';
import { IRequestResponse } from './index';

class Notification extends Api {
    ENDPOINT = 'notifications/'

    getList(clientId: number, page: number = 1): Promise<IRequestResponse> {
        return this.get(this.ENDPOINT, { clientId, page });
    }
}

const notification = new Notification();

export default notification;