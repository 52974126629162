import React from 'react';
import { connect } from 'react-redux';
import { create, getList, remove } from './../Actions/news';
import NewsListView from '../Components/News/List';
import Routes from './../Shared/routes';
import News from './../Entities/News';

interface IProps {
    isLoading: boolean;
    error: string;
    list: News[];
    getNews: (page?: number) => void;
    remove: (nId: number) => void;
    currentPage: number;
    pages: number;
    history: any;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class NewsListPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        await this.props.getNews();
    }

    onRemove = async (nId: number) => {
        await this.props.remove(nId);
        this.props.history.goBack();
    }

    onAdd = () => {
        this.props.history.push(Routes.NEWS_CREATE);
    }

    onLoadMore = (page: number) => {
        this.props.getNews(page);
    }

    render() {
        const { error, isLoading, list, currentPage, pages } = this.props;
        return (
            <NewsListView
                pages={pages}
                currentPage={currentPage}
                onLoadMore={this.onLoadMore}
                error={error}
                isLoading={isLoading}
                list={list}
                onAdd={this.onAdd}
                remove={this.onRemove}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getNews: (page?: number) => dispatch(getList(page)),
        remove: (nId: number) => dispatch(remove(nId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.news.isLoading,
        list: state.news.list,
        error: state.news.error,
        currentPage: state.news.currentPage,
        pages: state.news.pages
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(NewsListPage);
