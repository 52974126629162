import NewsApi from './../Api/news';
import { mapNews, mapOneNews } from '../Shared/mappers';
import { INewsEdit } from '../Shared/interfaces';
import News from '../Entities/News';

export const SET_ERROR = 'SET_NEWS_ERROR';
export const SET_LIST = 'SET_NEWS_LIST';
export const SET_ITEM = 'SET_NEWS_ITEM';
export const SET_LOADING = 'SET_NEWS_LOADING';


export function create(newsData: INewsEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const image = newsData.image;
        delete newsData.image;
        const createResponse = await NewsApi.create(newsData);
        let error = createResponse.error;
        if (createResponse.result) {
            const uploadResponse = await NewsApi.uploadImage(createResponse.data.id, image);
            error = uploadResponse.error;
            if (uploadResponse.result) {
                return dispatch(getList());
            }
        }
        return dispatch({
            type: SET_ERROR,
            error
        });

    }
}

export function edit(id: number, newsData: INewsEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const image = newsData.image;
        delete newsData.image;
        const editResponse = await NewsApi.edit(id, newsData);
        let error = editResponse.error;
        if (editResponse.result) {
            if (image) {
                const uploadResponse = await NewsApi.uploadImage(editResponse.data.id, image);
                error = uploadResponse.error;
            }
        }

        if (error) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        return dispatch(getList());
    }
}

export function getList(page = 1) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await NewsApi.getList(page);
        if (result) {
            return dispatch({
                type: SET_LIST,
                pages: data.pages,
                currentPage: data.currentPage,
                data: mapNews(data.list)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function getListByStoreObject(storeObjectId: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await NewsApi.getListByStoreObject(storeObjectId);
        if (result) {
            return dispatch({
                type: SET_LIST,
                pages: data.pages,
                currentPage: data.currentPage,
                data: mapNews(data.list)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function getOne(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await NewsApi.getById(id);
        if (result) {
            return dispatch({
                type: SET_ITEM,
                item: mapOneNews(data)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function remove(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await NewsApi.remove(id);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList());
    }
}