import React from 'react';
import Loading from '../Loading';
import { NewsSourceStatus } from '../../Shared/const';
import NewsSource from '../../Entities/NewsSource';
import { ERROR_MESSAGES } from '../../Shared/errors';

interface IState {
    title: string;
    link: string;
    status: number;
}

interface IProps {
    newsSource?: NewsSource;
    error: number | null;
    onSubmit: (title: string, link: string, status: number) => void;
    isLoading: boolean;
}

export default class ItemNewsSource extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            title: '',
            link: '',
            status: NewsSourceStatus.ACTIVE
        }
    }

    componentDidUpdate(oldProps: IProps) {
        if (this.props.newsSource && !oldProps.newsSource) {
            let title = '', link = '', status = NewsSourceStatus.ACTIVE;
            const newsSource: NewsSource | undefined = this.props.newsSource;
            title = newsSource.getTitle();
            link = newsSource.getLink();
            status = newsSource.getStatus();
            this.setState({
                title,
                link,
                status
            });
        }
    }

    handleChangeTitle = (event: any) => {
        const target = event.target;
        this.setState({
            title: target.value
        });
    }

    handleChangeLink = (event: any) => {
        const target = event.target;
        this.setState({
            link: target.value
        });
    }

    handleChangeStatus = (event: any) => {
        const target = event.target;
        this.setState({
            status: Number(target.value)
        });
    }

    onSubmit = () => {
        const { title, link, status } = this.state;
        if (title && link && status) {
            this.props.onSubmit(this.state.title, this.state.link, this.state.status);
        }
    }

    render() {
        const { isLoading, error } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (<div className="row">
                <div className="col-md-12">
                    <div className="tile">
                        <div className="row">
                            <div className="col-lg-6">
                                {error ?
                                    <p>{ERROR_MESSAGES[error]}</p> : null}
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Название</label>
                                        <input
                                            className="form-control"
                                            name="title"
                                            value={this.state.title}
                                            id="exampleInputEmail1"
                                            type="text"
                                            onChange={this.handleChangeTitle}
                                            placeholder="Введите название" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Ссылка</label>
                                        <input
                                            value={this.state.link}
                                            className="form-control"
                                            name="link"
                                            placeholder="Вставьте ссылку"
                                            onChange={this.handleChangeLink}
                                            id="exampleInputEmail1" type="text" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleSelect2">Статус</label>
                                        <select onChange={this.handleChangeStatus} className="form-control" id="exampleSelect2" >
                                            <option
                                                selected={NewsSourceStatus.ACTIVE == this.state.status}
                                                value={NewsSourceStatus.ACTIVE}>Активный</option>
                                            <option
                                                selected={NewsSourceStatus.INACTIVE == this.state.status}
                                                value={NewsSourceStatus.INACTIVE}>Неактивный</option>
                                        </select>
                                    </div>
                                </form>
                                <div className="tile-footer">
                                    <button
                                        onClick={this.onSubmit}
                                        className="btn btn-primary"
                                        type="submit">Сохранить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}