import React from 'react';
import { connect } from 'react-redux';
import { getList, remove } from '../Actions/store-object-category';
import ListStoreObject from '../Components/StoreObjectCategory/List';
import { StoreObjectCategory } from '../Entities/StoreObjectCategory';
import Routes from './../Shared/routes';

interface IProps {
    isLoading: boolean;
    error: string;
    list: StoreObjectCategory[];
    getList: () => void;
    history: any;
  remove: (id: number) => void;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class StoreObjectCategoryList extends React.Component<IProps, IState> {

    async componentDidMount() {
        if (!this.props.list.length) {
            await this.props.getList();
        }
    }

    onAdd = () => {
      this.props.history.push(Routes.STORE_OBJECT_CATEGORY_CREATE);
  }

  onRemove = async (nId: number) => {
    await this.props.remove(nId);
    this.props.history.goBack();
}


    render() {
        const { isLoading, list, } = this.props;
        return (
            <ListStoreObject
                isLoading={isLoading}
                list={list}
                add={this.onAdd}
                remove={this.onRemove}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getList: () => dispatch(getList()),
        remove: (id: number) => dispatch(remove(id)),
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.storeObjectCategory.isLoading,
        list: state.storeObjectCategory.list,
        error: state.storeObjectCategory.error,
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(StoreObjectCategoryList);
