import NewsSource from "../Entities/NewsSource";
import NewsCategory from "../Entities/NewsCategory"
import News from "../Entities/News";
import Request from "../Entities/Request";
import Notification from "../Entities/Notification";
import Client from "../Entities/Client";
import CarsStoreObject from "../Entities/CarsStoreObject";
import City from "../Entities/City";
import Manager from "../Entities/Manager";
import { Log } from "../Entities/Log";


export function mapNewsSources(data: any[]) {
    return data.map((item: any) => {
        return new NewsSource(item.id, item.title, item.link, item.status);
    })
}

export function mapStoreObject(data: any[]) {
    return data.map((item: any) => {
        return new CarsStoreObject({
            id: item.id,
            title: item.name,
            link: item.site,
            phone: item.phoneNumber,
            chatChannelId: item.chatChannelId,
            isDealer: item.isDealer,
            isHaveNewAuto: item.isHaveNewAuto,
            isHaveRepairStation: item.isHaveRepairStation,
            isHaveTestDrive: item.isHaveTestDrive,
            isHaveTradeIn: item.isHaveTradeIn,
            isHaveCommission: item.isHaveCommission,
            imageSrc: item.imageSrc,
            address: item.address,
            isVisible: item.isVisible,
            isVisibleIOS: item.isVisibleIOS,
            sortOrder: item.sortOrder,
            categoryId: item.categoryId,
        });
    })
}

export function mapNewsCategories(data: any[]) {
    return data.map((item: any) => {
        return new NewsCategory(item.id, item.title, item.color);
    })
}

export function mapNews(data: any[]) {
    return data.map((item: any) => {
        return mapOneNews(item);
    })
}

export function mapOneNews(item: any): News {
    const category = new NewsCategory(item.category.id, item.category.title);
    const source = new NewsSource(item.source.id, item.source.title);
    let storeObject = null;
    if (item.carsStoreObject) {
        storeObject = new CarsStoreObject({
            id: item.carsStoreObject.id,
            title: item.carsStoreObject.title
        });
    }
    return new News(item.id, item.title, item.body, item.imageSrc, category, source, item.endAt, item.isVisible, storeObject);
}

export function mapRequest(data: any[]) {
    return data.map((item: any) => {
        const client = new Client({
            id: item.client.id,
            phoneNumber: item.client.phoneNumber,
            email: item.client.email,
            firstName: item.client.firstName,
            lastName: item.client.lastName
        });
        let service;
        if(item.storeObject?.id){
          service = new CarsStoreObject({
            id: item.carsStoreObject.id,
            title: item.carsStoreObject.title
        });
        }
        return new Request(item.id, item.type, client, item.status, item.createdAt, service);
    })
}

export function mapCity(data: any[]) {
    return data.map((item: any) => {
        return new City(item.id, item.name);
    });
}

export function mapManager(data: any[]) {
    return data.map((item: any) => {
        return new Manager(item.id, item.firstName, item.lastName, item.email, item.role);
    });
}

export function mapClients(data: any[]) {
    return data.map((item: any) => {
        return mapClient(item);
    });
}

export function mapNotifications(data: any[]) {
    return data.map((item: any) => {
        return new Notification(item);
    });
}

export function mapLog(data: any[]) {
    return data.map((item: any) => {
        return new Log(item.id, item.clientId, item.type, item.message, item.createdAt);
    });
}

export function mapClient(item: any): Client {
    return new Client({
        id: item.id,
        phoneNumber: item.phoneNumber,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        dateOfBirth: item.dateOfBirth,
        sex: item.sex,
        avatarSrc: item.avatarSrc
    });
}