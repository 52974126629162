import React from 'react';
import { connect } from 'react-redux';
import { getList, remove } from '../Actions/managers';
import ManagerListView from '../Components/Manager/List';
import Manager from '../Entities/Manager';
import Routes from '../Shared/routes';

interface IProps {
    isLoading: boolean;
    error: string;
    list: Manager[];
    getList: () => void;
    remove: (mId: number) => void;
    history: any;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class ManagerListPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        if (!this.props.list.length) {
            await this.props.getList();
        }
    }

    onRemove = async (nId: number) => {
        await this.props.remove(nId);
        this.props.history.goBack();
    }

    onAdd = () => {
        this.props.history.push(Routes.MANAGER_CREATE);
    }

    render() {
        const { error, isLoading, list } = this.props;
        return (
            <ManagerListView
                add={this.onAdd}
                remove={this.onRemove}
                error={error}
                isLoading={isLoading}
                list={list}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getList: () => dispatch(getList()),
        remove: (mId: number) => dispatch(remove(mId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.manager.isLoading,
        list: state.manager.list,
        error: state.manager.error,
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(ManagerListPage);
