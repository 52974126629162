import StoreObjectAPI from '../Api/storeObject';
import { mapStoreObject } from '../Shared/mappers';
import { IStoreObjectEdit } from '../Shared/interfaces';

export const SET_ERROR = 'SET_STORE_OBJECT_ERROR';
export const SET_LIST = 'SET_STORE_OBJECT_LIST';
export const SET_LOADING = 'SET_STORE_OBJECT_LOADING';


export function edit(id: number, data: IStoreObjectEdit) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error } = await StoreObjectAPI.edit(id, data);
        if (!result) {
            return dispatch({
                type: SET_ERROR,
                error
            });
        }
        dispatch(getList())

    }
}

export function getList(page: number = 1) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await StoreObjectAPI.getList(page);
        if (result) {
            return dispatch({
                type: SET_LIST,
                data: mapStoreObject(data.list)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

// export function remove(id: number) {
//     return async (dispatch: any) => {
//         dispatch({
//             type: SET_LOADING,
//             isLoading: true
//         });
//         const { result, error } = await StoreObjectAPI.remove(id);
//         if (!result) {
//             return dispatch({
//                 type: SET_ERROR,
//                 error
//             });
//         }
//         dispatch(getList());
//     }
// }