import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './../Pages/Home';
import Routes from './../Shared/routes';
import AddManager from '../Pages/AddManager';
import EditManager from '../Pages/EditManager';
import AddNewsSource from '../Pages/AddNewsSource';
import EditNewsSource from '../Pages/EditNewsSource';
import NewsSourceList from '../Pages/NewsSourceList';
import EditStoreObject from '../Pages/EditStoreObject';
import StoreObjectList from '../Pages/StoreObjectList';
import NewsCategoryList from '../Pages/NewsCategoryList';
import RequestList from '../Pages/RequestList';
import EditNewsCategory from '../Pages/EditNewsCategory';
import AddNewsCategory from '../Pages/AddNewsCategory';
import AddNews from '../Pages/AddNews';
import EditNews from '../Pages/EditNews';
import NewsList from '../Pages/NewsList';
import ManagerList from '../Pages/ManagerList';
import ClientList from '../Pages/ClientList';
import Privacy from '../Pages/Privacy';
import ClientItem from '../Pages/ClientItem';
import NewsListByCarsStoreObject from '../Pages/NewsListByCarsStoreObject';
import NotificationList from '../Pages/NotificationList';
import RequestListByClient from '../Pages/RequestListByClient';
import StaticRoadHelp from '../Pages/StaticRoadHelp';
import StaticTuning from '../Pages/StaticTuning';
import LogList from '../Pages/LogList';
import UploadApk from '../Pages/UploadApk';
import AddStoreObjectCategory from '../Pages/AddStoreObjectCategory';
import EditStoreObjectCategory from '../Pages/EditStoreObjectCategory';
import { StoreObjectCategory } from '../Entities/StoreObjectCategory';
import StoreObjectCategoryList from '../Pages/StoreObjectCategoryList';

export default () => {
    return (
        <Switch>
            <Route path={`${Routes.APP_UPDATE}`} component={UploadApk} />
            <Route path={`${Routes.APP_ERROR_LOG}:clientId`} component={LogList} />
            <Route path={`${Routes.APP_ERROR_LOG}`} component={LogList} />
            <Route path={Routes.MANAGER_CREATE} component={AddManager} />
            <Route path={Routes.PRIVACY} component={Privacy} />
            <Route path={Routes.EDIT_STATIC_ROAD_HELP} component={StaticRoadHelp} />
            <Route path={Routes.EDIT_STATIC_TUNNING} component={StaticTuning} />
            <Route path={Routes.NEWS_CREATE} component={AddNews} />
            <Route path={`${Routes.NEWS_EDIT}:newsId`} component={EditNews} />
            <Route path={`${Routes.MANAGER_EDIT}:managerId`} component={EditManager} />
            <Route path={Routes.NEWS_LIST} component={NewsList} />
            <Route path={`${Routes.NEWS_LIST_BY_STORE_OBJECT}:storeObjectId`} component={NewsListByCarsStoreObject} />
            <Route path={Routes.CLIENT_LIST} component={ClientList} />
            <Route path={`${Routes.CLIENT_ITEM}:clientId`} component={ClientItem} />
            <Route path={Routes.MANAGER_LIST} component={ManagerList} />
            <Route path={`${Routes.NOTIFICATION_LIST}:clientId`} component={NotificationList} />
            {/* <Route path={Routes.STORE_OBJECT_CREATE} component={AddStoreObject} /> */}
            <Route path={`${Routes.STORE_OBJECT_EDIT}:storeObjectId`} component={EditStoreObject} />
            <Route path={Routes.STORE_OBJECT_LIST} component={StoreObjectList} />
            <Route path={Routes.NEWS_CATEGORY_CREATE} component={AddNewsCategory} />
            <Route path={`${Routes.NEWS_CATEGORY_EDIT}:newsCategoryId`} component={EditNewsCategory} />
            <Route path={Routes.NEWS_CATEGORY_LIST} component={NewsCategoryList} />
            <Route path={Routes.STORE_OBJECT_CATEGORY_CREATE} component={AddStoreObjectCategory} />
            <Route path={`${Routes.STORE_OBJECT_CATEGORY_EDIT}/:storeObjectCategoryId`} component={EditStoreObjectCategory} />
            <Route path={Routes.STORE_OBJECT_CATEGORY_LIST} component={StoreObjectCategoryList} />
            <Route path={Routes.NEWS_SOURCE_CREATE} component={AddNewsSource} />
            <Route path={`${Routes.NEWS_SOURCE_EDIT}:newsSourceId`} component={EditNewsSource} />
            <Route path={Routes.NEWS_SOURCE_LIST} component={NewsSourceList} />
            <Route path={Routes.REQUEST_LIST} component={RequestList} />

            <Route path={`${Routes.REQUEST_LIST_BY_CLIENT}:clientId`} component={RequestListByClient} />
            <Route exact path={Routes.HOME} component={Home} />
        </Switch>
    );
};


