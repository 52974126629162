import { SET_ROAD_HELP_DATA, SET_TUNNING_DATA, SET_LOADING, SET_ERROR } from '../Actions/staticPage';

interface IState {
	tunning: string;
	tunningPhoneNumber: string;
	roadHelp: string;
	roadHelpPhoneNumber: string;
	error: number | null;
	isLoading: boolean;
};

const defaultState = {
	error: null,
	tunning: '',
	tunningPhoneNumber: '',
	roadHelp: '',
	roadHelpPhoneNumber: '',
	isLoading: false
};

export default function privacy(state: IState = defaultState, action: any) {
	switch (action.type) {
		case SET_ROAD_HELP_DATA:
			return {
				...state,
				roadHelp: action.roadHelp,
				roadHelpPhoneNumber: action.roadHelpPhoneNumber,
				isLoading: false
			};
		case SET_TUNNING_DATA:
			return {
				...state,
				tunning: action.tunning,
				tunningPhoneNumber: action.tunningPhoneNumber,
				isLoading: false
			};
		case SET_LOADING:
			return {
				...state,
				isLoading: action.isLoading
			}
		case SET_ERROR: {
			return {
				...state,
				isLoading: false,
				error: action.error
			}
		}
		default:
			return state;
	}
}
