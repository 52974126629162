import { BaseEntity } from "./BaseEntity";

interface IConstructor {
    id: number;
    title: string;
    link?: string;
    imageSrc?: string;
    phone?: string;
    address?: string;
    openAt?: string;
    closeAt?: string;
    isDealer?: boolean;
    isHaveRepairStation?: boolean;
    isHaveTradeIn?: boolean;
    isHaveTestDrive?: boolean;
    isHaveNewAuto?: boolean;
    isHaveCommission?: boolean
    isVisible?: boolean;
    isVisibleIOS?: boolean;
    chatChannelId?: number | null;
    sortOrder?: number;
    categoryId?: number;
}

export default class CarsStoreObject extends BaseEntity {
    public title: string;
    public link: string | undefined;
    public imageSrc: string | undefined;
    public phone: string | undefined;
    public address: string | undefined;
    public openAt: string | undefined;
    public closeAt: string | undefined;
    public isDealer: boolean | undefined;
    public isHaveRepairStation: boolean | undefined;
    public isHaveTradeIn: boolean | undefined;
    public isHaveTestDrive: boolean | undefined;
    public isHaveNewAuto: boolean | undefined;
    public isHaveCommission: boolean | undefined;
    public chatChannelId: number | null;
    public sortOrder: number | undefined;
    public categoryId: number | undefined;
    public isVisible?: boolean = true;
    public isVisibleIOS?: boolean = true;

    constructor({ id, address, imageSrc, isHaveNewAuto, isHaveRepairStation, isHaveTradeIn,
        isHaveTestDrive,categoryId, isDealer, isHaveCommission, sortOrder, chatChannelId = null, link, title, phone, closeAt, openAt, isVisible, isVisibleIOS }: IConstructor) {
        super(id);
        this.title = title;
        this.address = address;
        this.imageSrc = imageSrc;
        this.isHaveNewAuto = isHaveNewAuto;
        this.isHaveRepairStation = isHaveRepairStation;
        this.isHaveTradeIn = isHaveTradeIn;
        this.isHaveTestDrive = isHaveTestDrive;
        this.isDealer = isDealer;
        this.chatChannelId = chatChannelId;
        this.isHaveCommission = isHaveCommission;
       this.sortOrder = sortOrder;
        this.phone = phone;
        this.link = link;
        this.closeAt = closeAt;
        this.openAt = openAt;
        this.isVisible = isVisible;
        this.isVisibleIOS = isVisibleIOS;
        this.categoryId = categoryId;
    }
}