import LogApi from './../Api/log';
import { mapLog } from '../Shared/mappers';

export const SET_ERROR = 'SET_LOG_ERROR';
export const SET_LIST = 'SET_LOG_LIST';
export const SET_LOADING = 'SET_LOG_LOADING';


export function getList(page: number = 1, clientId: number | undefined = undefined) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await LogApi.getList(page, clientId);
        if (result) {
            return dispatch({
                type: SET_LIST,
                pages: data.pages,
                currentPage: data.currentPage,
                data: mapLog(data.list)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}
