import React from 'react';
import { connect } from 'react-redux';
import { create, getListByStoreObject, remove } from '../Actions/news';
import NewsListView from '../Components/News/List';
import Routes from '../Shared/routes';
import News from '../Entities/News';
import store from '../store';

interface IProps {
    isLoading: boolean;
    error: string;
    list: News[];
    getListByStoreObject: (storeObjectId: number) => void;
    remove: (nId: number) => void;
    currentPage: number;
    pages: number;
    history: any;
    match: any;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class NewsListByCarsStoreObjectPage extends React.Component<IProps, IState> {
    id: number;

    constructor(props: IProps){
        super(props);
        this.id = props.match.params.storeObjectId;
    }

    async componentDidMount() {
        if(!this.id){
            this.props.history.push(Routes.NEWS_LIST);
        }
        await this.props.getListByStoreObject(this.id);
    }

    onRemove = async (nId: number) => {
        await this.props.remove(nId);
        this.props.history.goBack();
    }

    onAdd = () => {
        this.props.history.push(Routes.NEWS_CREATE);
    }

    onLoadMore = (page: number) => {
        this.props.getListByStoreObject(this.id);
    }

    render() {
        const { error, isLoading, list, currentPage, pages } = this.props;
        let storeObject = null;
        if(list[0]){
            storeObject = list[0].getCarsStoreObject()
        }
        return (
            <NewsListView
                pages={pages}
                currentPage={currentPage}
                onLoadMore={this.onLoadMore}
                error={error}
                isLoading={isLoading}
                list={list}
                storeObject={storeObject}
                onAdd={this.onAdd}
                remove={this.onRemove}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getListByStoreObject: (storeObjectId: number) => dispatch(getListByStoreObject(storeObjectId)),
        remove: (nId: number) => dispatch(remove(nId))
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.news.isLoading,
        list: state.news.list,
        error: state.news.error,
        currentPage: state.news.currentPage,
        pages: state.news.pages
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(NewsListByCarsStoreObjectPage);
