import moment from 'moment';
import { BaseEntity } from "./BaseEntity";
import { NOTIFICATION_STATUS, NOTIFICATION_TYPE, NOTIFICATION_TYPE_TITLE } from "../Shared/const";
import { stat } from 'fs';

export default class Notification extends BaseEntity {
    private title: string;
    private type: NOTIFICATION_TYPE;
    private body: string;
    private shortBody: string;
    private status: number;
    private createdAt: Date;

    constructor({ id, title, body, type, status, shortBody, createdAt }: any) {
        super(id);
        this.title = title;
        this.body = body;
        this.shortBody = shortBody;
        this.createdAt = new Date(createdAt);
        this.type = type;
        this.status = status;
    }

    getTitle(): string {
        return this.title;
    }

    getBody(): string {
        return this.body;
    }

    getStringType(): string {
        return NOTIFICATION_TYPE_TITLE[this.type];
    }

    getCreatedAt(): string {
        return moment(this.createdAt).format("DD MMMM YYYY, HH:mm")
    }

    getStatus(): string {
        return NOTIFICATION_STATUS.OPEN == this.status ? 'Просмотрено' : 'Не просмотрено';
    }
}