import React from 'react';
import Client from '../../Entities/Client';
import Loading from '../Loading';

interface IProps {
	client: Client | undefined;
	isLoading: boolean;
}

export default class ClientItem extends React.Component<IProps> {
	render() {
		const { client, isLoading } = this.props;
		if (!client || isLoading) {
			return <Loading />;
		}
		return (
			<>
				<div className="app-title">
					<div>
						<h1><i className="fa fa-user"></i> Профиль клиента</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="tile">
							<div className="row mb-4">
								<div className="col-6">
									<h2 className="page-header">{client.getName()}</h2>
								</div>
							</div>
							{client.getAvatar() ?
								<div className="app-sidebar__user">
									<img className="app-sidebar__user-avatar" src={String(client.getAvatar())} alt="User Image" />
								</div> : null}
							<div className="row">
								<div className="col-12 table-responsive">
									<table className="table table-striped">
										<tbody>
											<tr>
												<td>Id</td>
												<td>{client.getId()}</td>
											</tr>
											<tr>
												<td>Номер телефона</td>
												<td>{client.getPhoneNumber()}</td>
											</tr>
											<tr>
												<td>Почта</td>
												<td>{client.getEmail()}</td>
											</tr>
											<tr>
												<td>Пол</td>
												<td>{client.getSex()}</td>
											</tr>
											<tr>
												<td>Дата рождения</td>
												<td>{client.getDateOfBirth()}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}