import moment from 'moment';
import { BaseEntity } from "./BaseEntity";
import { SEX } from '../Shared/const';
import { STATIC_FILE_URL } from '../config';


interface IConstructor {
    id: number;
    phoneNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    sex?: SEX;
    dateOfBirth?: string;
    avatarSrc?: string;
}

export default class Client extends BaseEntity {
    private phoneNumber: string;
    private email: string;
    private firstName: string;
    private lastName: string;
    private sex: SEX | undefined;
    private avatarSrc: string | undefined;
    private dateOfBirth: Date | undefined;

    constructor({
        id,
        phoneNumber,
        email,
        firstName,
        lastName,
        sex,
        avatarSrc,
        dateOfBirth
    }: IConstructor) {
        super(id);
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.sex = sex;
        this.avatarSrc = avatarSrc;
        if (dateOfBirth) {
            this.dateOfBirth = new Date(dateOfBirth);
        }
    }

    getPhoneNumber(): string {
        return this.phoneNumber;
    }

    getName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    getEmail(): string {
        return `${this.email}`;
    }

    getSex(): string {
        if (this.sex == SEX.FEMALE) {
            return 'Женщина';
        }
        return 'Мужчина';
    }

    getDateOfBirth(): string {
        return moment(this.dateOfBirth).format('DD MMMM YYYY');
    }

    getAvatar(): string | null {
        if(!this.avatarSrc){
            return null;
        }
        return `${STATIC_FILE_URL}${this.avatarSrc}`;
    }
}