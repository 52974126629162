import React from 'react';
import { connect } from 'react-redux';
import { getList } from '../Actions/storeObject';
import ListStoreObject from '../Components/StoreObject/List';
import CarsStoreObject from '../Entities/CarsStoreObject';

interface IProps {
    isLoading: boolean;
    error: string;
    list: CarsStoreObject[];
    getList: (page?: number) => void;
    history: any;
    currentPage: number;
    pages: number;
}

interface IState {
    isLoading: boolean;
    isLogin: boolean;
}

class NewsListPage extends React.Component<IProps, IState> {

    async componentDidMount() {
        if (!this.props.list.length) {
            await this.props.getList();
        }
    }

    onLoadMore = (page: number) => {
        this.props.getList(page);
    }

    render() {
        const { error, isLoading, list, currentPage, pages } = this.props;
        return (
            <ListStoreObject
                pages={pages}
                currentPage={currentPage}
                onLoadMore={this.onLoadMore}
                error={error}
                isLoading={isLoading}
                list={list}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        getList: (page?: number) => dispatch(getList(page)),
    };
}

const mapStateToProps = (state: any) => {
    return {
        isLoading: state.storeObject.isLoading,
        list: state.storeObject.list,
        error: state.storeObject.error,
        currentPage: state.storeObject.currentPage,
        pages: state.storeObject.pages
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(NewsListPage);
