import React from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination';
import Loading from '../Loading';
import NewsSource from '../../Entities/NewsSource';
import Routes from './../../Shared/routes';
import { NewsSourceStatusTitles } from '../../Shared/const';


interface IProps {
    pages: number;
    currentPage: number;
    onLoadMore: (page: number) => void;
    list: NewsSource[];
    error: string;
    remove: (newsSourceId: number) => void;
    addSource: () => void;
    isLoading: boolean;
}

export default class NewsSourceList extends React.Component<IProps> {

    onPage = (page: number) => {
        this.props.onLoadMore(page);
    }

    onAddSource = () => {
        this.props.addSource();
    }

    renderItem = (item: NewsSource) => {
        let status = NewsSourceStatusTitles[String(item.getStatus())]
        return (
            <tr>
                <td>{item.getId()}</td>
                <td>{item.getTitle()}</td>
                <td>
                    <a href={item.getLink()}>{item.getLink()}</a>
                </td>
                <td>{status}</td>
                <td>
                    <Link to={`${Routes.NEWS_SOURCE_EDIT}${item.getId()}`}>Редактировать</Link><br />
                    <a href="#" onClick={() => this.props.remove(item.getId())}>Удалить</a>
                </td>
            </tr>
        )
    }

    render() {
        const { isLoading, list } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (
                <>

                    <div className="app-title">
                        <div>
                            <h1><i className="fa fa-th-list"></i> Источники новостей</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="tile">
                                <button
                                    onClick={this.onAddSource}
                                    className="btn btn-primary"
                                    type="submit">Добавить источник</button>
                                <br />
                                <br />
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Название</th>
                                            <th>Ссылка</th>
                                            <th>Статус</th>
                                            <th>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map(this.renderItem)}
                                    </tbody>
                                </table>
                                <Pagination
                                    onPage={this.onPage}
                                    pages={this.props.pages}
                                    current={this.props.currentPage} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}