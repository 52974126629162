import React from 'react';
import { connect } from 'react-redux';
import { create } from './../Actions/news';
import { getList as getStoreObjects } from './../Actions/storeObject';
import { getList as getNewsCategory } from './../Actions/newsCategory';
import { getList as getNewsSource } from './../Actions/newsSource';
import Routes from './../Shared/routes';
import AddNewsView from '../Components/News/Item';
import { INewsEdit } from '../Shared/interfaces';
import NewsSource from '../Entities/NewsSource';
import NewsCategory from '../Entities/NewsCategory';
import Loading from '../Components/Loading';
import CarsStoreObject from '../Entities/CarsStoreObject';
import { FILL_PARAMS_ERROR } from '../Shared/errors';


interface IProps {
    isLoading: boolean;
    isSourceLoading: boolean;
    isCategoryLoading: boolean;
    error: number;
    create: (data: INewsEdit) => void;
    newsSourceList: NewsSource[];
    newsCategoryList: NewsCategory[];
    carsStoreObjectList: CarsStoreObject[];
    getStoreObjects: () => void;
    getNewsSource: () => void;
    getNewsCategory: () => void;
    history: any;
}

interface IState {
    error: number | null;
}

class AddNewsPage extends React.Component<IProps, IState> {

    state = {
        error: null
    }

    async componentDidMount() {
        if (!this.props.newsCategoryList.length) {
            await this.props.getNewsCategory();
        }
        if (!this.props.newsSourceList.length) {
            await this.props.getNewsSource();
        }
        if (!this.props.carsStoreObjectList.length) {
            await this.props.getStoreObjects();
        }
    }

    onSubmit = async (data: INewsEdit) => {
        if (!(data.title && data.body && data.image && data.sourceId && data.categoryId)) {
            this.setState({
                error: FILL_PARAMS_ERROR
            });
        }
        else {
            data.endAt = new Date(data.endAt);
            await this.props.create(data);
            if (!this.props.error) {
                this.props.history.push(Routes.NEWS_LIST)
            }
        }
    }

    render() {
        if (this.props.isCategoryLoading || this.props.isSourceLoading) {
            return <Loading />;
        }
        return (
            <AddNewsView
                error={this.props.error || this.state.error}
                categoryList={this.props.newsCategoryList}
                sourceList={this.props.newsSourceList}
                carsStoreObjectList={this.props.carsStoreObjectList}
                isLoading={this.props.isLoading}
                onSubmit={this.onSubmit}
            />
        );
    }
}

function bindAction(dispatch: any) {
    return {
        create: (data: INewsEdit) => dispatch(create(data)),
        getNewsCategory: () => dispatch(getNewsCategory()),
        getNewsSource: () => dispatch(getNewsSource()),
        getStoreObjects: () => dispatch(getStoreObjects())
    };
}

const mapStateToProps = (state: any) => {
    return {
        newsCategoryList: state.newsCategory.list,
        newsSourceList: state.newsSource.list,
        carsStoreObjectList: state.storeObject.list,
        isLoading: state.news.isLoading,
        isSourceLoading: state.newsSource.isLoading,
        isCategoryLoading: state.newsCategory.isLoading,
        error: state.newsSource.error
    }
}

export default connect(
    mapStateToProps,
    bindAction
)(AddNewsPage);
