import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import Pagination from '../Pagination';
import CarsStoreObject from '../../Entities/CarsStoreObject';
import Routes from './../../Shared/routes';


interface IProps {
    pages: number;
    currentPage: number;
    onLoadMore: (page: number) => void;
    list: CarsStoreObject[];
    error: string;
    isLoading: boolean;
}

export default class RequestList extends React.Component<IProps> {

    onPage = (page: number) => {
        this.props.onLoadMore(page);
    }

    renderItem = (item: CarsStoreObject) => {
        return (
            <tr>
                <td>{item.getId()}</td>
                <td>{item.title}</td>
                <td>{item.sortOrder}</td>
                <td>
                    <Link to={`${Routes.STORE_OBJECT_EDIT}${item.getId()}`}>Редактировать</Link><br />
                    <Link to={`${Routes.NEWS_LIST_BY_STORE_OBJECT}${item.getId()}`}>Новости</Link><br />
                </td>
            </tr>
        )
    }

    render() {
        const { isLoading, list } = this.props;
        if (isLoading) {
            return <Loading />;
        }
        else {
            return (
                <>

                    <div className="app-title">
                        <div>
                            <h1><i className="fa fa-th-list"></i> Салоны</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10">
                            <div className="tile">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Заголовок</th>
                                            <th>Позиция</th>
                                            <th>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map(this.renderItem)}
                                    </tbody>
                                </table>
                                <Pagination
                                    onPage={this.onPage}
                                    pages={this.props.pages}
                                    current={this.props.currentPage} />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}