import Api from '.';
import { IRequestResponse } from './index';

class StaticPage extends Api {
	ENDPOINT = 'static/'

	savePolicy(policy: string): Promise<IRequestResponse> {
		return this.post(`${this.ENDPOINT}policy`, {
			value: policy
		});
	}

	saveUserAgreegment(userAgreegment: string): Promise<IRequestResponse> {
		return this.post(`${this.ENDPOINT}userAgreegment`, {
			value: userAgreegment
		});
	}

	saveTunning(value: string, phoneNumber: string): Promise<IRequestResponse> {
		return this.post(`${this.ENDPOINT}tuning`, {
			value,
			phoneNumber
		});
	}

	saveRoadHelp(policy: string, phoneNumber: string): Promise<IRequestResponse> {
		return this.post(`${this.ENDPOINT}road-help`, {
			value: policy,
			phoneNumber
		});
	}

	getPrivacy(): Promise<IRequestResponse> {
		return this.get(`${this.ENDPOINT}privacy`);
	}

	getTunning(): Promise<IRequestResponse> {
		return this.get(`${this.ENDPOINT}tuning`);
	}

	getRoadHelp(): Promise<IRequestResponse> {
		return this.get(`${this.ENDPOINT}road-help`);
	}
}

const sp = new StaticPage();

export default sp;