import ClientAPI from './../Api/client';
import { mapClient, mapClients } from '../Shared/mappers';

export const SET_ERROR = 'SET_CLIENT_ERROR';
export const SET_LIST = 'SET_CLIENT_LIST';
export const SET_ITEM = 'SET_CLIENT_ITEM';
export const SET_LOADING = 'SET_CLIENT_LOADING';


export function getList(page = 1) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
        const { result, error, data } = await ClientAPI.getList(page);
        if (result) {
            return dispatch({
                type: SET_LIST,
                pages: data.pages,
                currentPage: data.currentPage,
                data: mapClients(data.list)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}

export function getOne(id: number) {
    return async (dispatch: any) => {
        dispatch({
            type: SET_LOADING,
            isLoading: true
        });
		const { result, error, data } = await ClientAPI.getById(id);
        if (result) {
            return dispatch({
                type: SET_ITEM,
                item: mapClient(data)
            });
        }
        return dispatch({
            type: SET_ERROR,
            error
        });
    }
}
